.react-calendar {

    width: 400px;

    max-width: 100%;

    background: white;

    border: 1px solid var( --details-color );

    line-height: 1.125em;

    border-radius: 0.3em;

    overflow: hidden;

    padding: 0.5em;

}

.react-calendar--doubleView {

    width: 800px;

}

.react-calendar--doubleView .react-calendar__viewContainer {

    display: flex;

    margin: -0.5em;

}

.react-calendar--doubleView .react-calendar__viewContainer > * {

    width: 50%;

    margin: 0.5em;

}

.react-calendar,

.react-calendar *,

.react-calendar *:before,

.react-calendar *:after {

    -moz-box-sizing: border-box;

    -webkit-box-sizing: border-box;

    box-sizing: border-box;

}

.react-calendar button {

    margin: 0;

    border: 0;

    outline: none;

}

.react-calendar button:enabled:hover {

    cursor: pointer;

}

.react-calendar__navigation {

    display: flex;

    height: 44px;

}

.react-calendar__navigation > button:not( :last-child ) {

    margin-right: 0.5em;

}

.react-calendar__navigation button {

    min-width: 44px;

    background: none;

    border-radius: 0.3em;

}

.react-calendar__navigation button:disabled {

    background-color: #f0f0f0;

    opacity: 0.5;

}

.react-calendar__navigation button:enabled:hover {

    background-color: var( --accent-focus-color );

}

.react-calendar__month-view__weekdays {
text-align: center;
text-transform: uppercase;
font-weight: bold;
font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
display: flex;
align-items: center;
justify-content: center;
font-size: 0.75em;
font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
padding: 2em 0.5em;
}
.react-calendar__tile {
max-width: 100%;
padding: 10px 6.6667px;
background: none;
text-align: center;
line-height: 16px;

    border-radius: 0.3em;
}

.react-calendar__tile:disabled {

opacity: 0.5;

background-color: #f0f0f0;

}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
background-color: var( --details-color );
}
.react-calendar__tile--now {
background: var( --footer-color );

color: white;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
background: #ffffa9;
}
.react-calendar__tile--hasActive {
background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
background: #a9d4ff;
}
.react-calendar__tile--active {
background: var( --accent-color );
color: white;

font-weight: 600;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
background: var( --accent-color );
}
.react-calendar--selectRange .react-calendar__tile--hover {
background-color: #e6e6e6;
}


button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {

    display: none;

}

