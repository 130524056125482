.container {

    border-top: 1px dashed #8e8e8e;

    padding-top: 2rem;

    margin-top: 2rem;

    display: flex
;
    flex-direction: row;

    align-items: center;

    gap: 2rem;

    flex-wrap: wrap;
    
    justify-content: center;

}

.notice {

    flex: 1;

    color: #8e8e8e;

}

.notice > *:not( :last-child ) {

    margin-bottom: 0.5rem;

}

.image {

    max-width: 256px;

}

.title {

    color: white;

    font-weight: 500;

}

.text {

    font-size: 0.875rem;

    line-height: 1.75;

}