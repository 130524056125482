.product-catalog > .layout {

    display: flex;

    flex-direction: row;

    flex-wrap: nowrap;

}

.product-catalog > .layout > aside {

    margin-right: 2em;

    width: 100%;

    max-width: 270px;

    min-width: 250px;

}

.product-catalog > .layout > aside > div:not( :last-child ) {

    margin-bottom: 2em;

}

.product-catalog > .layout > aside > .widget > div:not( :last-child ) {

    margin-bottom: 1em;

}

.product-catalog > .layout > aside > .widget {

    border-radius: 0.3em;

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

    padding: 1em;

}

.product-catalog > .layout > aside > .widget > .filter > div:not( :last-child ) {

    margin-bottom: 1em;

}

.product-catalog > .layout > aside > .widget > .filter > .title {

    display: flex;

    align-items: center;

    flex-wrap: nowrap;

    flex-direction: row;

    justify-content: space-between;

}

.product-catalog > .layout > aside > .widget > .filter > .title > .name {

    font-weight: 600;

}

.product-catalog > .layout > aside > .widget > .filter > .title > button {

    line-height: 0;

    margin-left: 1em;

    border: none;

    background: none;

    outline: none;

    transition: 0.2s ease;

    -o-transition: 0.2s ease;

    -webkit-transition: 0.2s ease;

    -moz-transition: 0.2s ease;

}

.product-catalog > .layout > aside > .widget > .filter > .title > button:hover {

    cursor: pointer;

    color: var( --accent-color );

}

.product-catalog > .layout > aside > .widget > .filter > .title > button.close {

    transform: rotate( 90deg );

}

.product-catalog > .layout > aside .title > h2 {

    text-transform: uppercase;

}

.product-catalog > .layout > section {

    flex: 1;

}

.product-catalog > .layout > section > div:not( :last-child ) {

    margin-bottom: 2em;

}

.product-catalog > .layout > section > .pagination button > svg,

.quick-search .pagination button > svg {

    pointer-events: none;

}

.product-catalog > .layout > section > .pagination button,

.quick-search .pagination button {

    line-height: 0;

    font-size: 20px;

    background: none;

    border: 2px solid var( --secondary-text-color );

    color: var( --secondary-text-color );

    border-radius: 50%;

    text-align: center;

    padding: 5px;

    outline: none;

}

.product-catalog > .layout > section > .pagination button:hover,

.quick-search .pagination button:hover {

    border-color: var( --accent-color );

    background-color: var( --accent-color );

    color: var( --white-color );

    cursor: pointer;

}

.product-catalog > .layout > section > .pagination button:disabled,

.quick-search .pagination button:disabled {

    opacity: 0.3;

}

.product-catalog > .layout > section > .pagination button:hover:disabled,

.quick-search .pagination button:hover:disabled {

    color: var( --secondary-text-color );

    border-color: var( --secondary-text-color );

    background-color: transparent;

    cursor: default;

}

.product-catalog > .layout > section > .pagination > button:not( :last-child ),

.quick-search .pagination button:not( :last-child ) {

    margin-right: 1em;

}

.product-catalog > .layout > section > .actions {

    display: flex;

    align-items: center;

    flex-direction: row;

    flex-wrap: nowrap;

    justify-content: flex-end;

}

.product-catalog > .layout > section > .actions > div:not( :last-child ) {

    margin-right: 2em;

}

.product-catalog > .layout > section > .actions > .display {

    line-height: 0;

}

.product-catalog > .layout > section > .actions > .display > button {

    background: none;

    line-height: 0;

    font-size: 18px;

    border: none;

    outline: none;

    color: var( --black-color );

}

.product-catalog > .layout > section > .actions > .display > button:hover {

    cursor: pointer;

    color: var( --accent-color );

}

.product-catalog > .layout > section > .actions > .display > button:disabled {

    opacity: 0.3;

}

.product-catalog > .layout > section > .actions > .display > button:hover:disabled {

    cursor: default;

    color: var( --black-color );

}

.product-catalog > .layout > section > .actions > .display > button:not( :last-child ) {

    margin-right: 0.5em;

    padding-right: 0.5em;

    border-right: 1px solid var( --details-color );

}

.product-catalog > .layout > section > .actions > .sort > span {

    margin-right: 0.5em;

    font-size: 0.8em;

}

.product-catalog > .layout > section > .products {

    display: grid;

    grid-gap: 2em;

}

.product-catalog > .layout > section > .products.grid {

    grid-template-columns: repeat( 3, 1fr );

}

.product-catalog > .layout > section > .products.list {

    grid-template-columns: 1fr;

}

.product-catalog > .layout > section > .products.grid > .item,

.product-catalog > .layout > section > .products.list > .item,

.quick-search > .results > .collection > .item {

    background-color: var( --white-color );

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

    padding: 1em;

    text-align: center;

    border-radius: 0.3em;

}

.product-catalog > .layout > section > .products.grid > .item > a > *:not( :last-child ),

.product-catalog > .layout > section > .products.grid > .item > *:not( :last-child ){

    margin-bottom: 1rem;

}

.product-catalog > .layout > section > .products.list > .item > div:not( :last-child ),

.quick-search > .results > .collection > .item > div:not( :last-child ) {

    margin-right: 2em;

}

.product-catalog > .layout > section > .products.grid > .item > a,

.product-catalog > .layout > section > .products.grid > .item {

    position: relative;

    display: block;

    text-decoration: none;

    outline: none;

}

.product-catalog > .layout > section > .products.list > .item,

.quick-search > .results > .collection > .item {

    position: relative;

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

    width: 100%;

}

.product-catalog > .layout > section > .products.grid > .item > a > .thumbnail,

.product-catalog > .layout > section > .products.grid > .item > .thumbnail,

.product-catalog > .layout > section > .products.list > .item > a > .thumbnail, 

.quick-search > .results > .collection > .item > a > .thumbnail {

    position: relative;

    overflow: hidden;

}

.product-catalog > .layout > section > .products.list > .item > a > .thumbnail,

.quick-search > .results > .collection > .item > a > .thumbnail {

    width: 200px;

    margin-right: 2em;

}

.product-catalog > .layout > section > .products.grid > .item > a > .thumbnail.tyres::after,

.product-catalog > .layout > section > .products.grid > .item > .thumbnail.tyres::after,

.product-catalog > .layout > section > .products.list > .item > a > .thumbnail::after,

.quick-search > .results > .collection > .item > a > .thumbnail::after {

    content: "";

    display: block;

    padding-bottom: 50%;

}

.product-catalog > .layout > section > .products.grid > .item > a > .thumbnail.tyres > img,

.product-catalog > .layout > section > .products.grid > .item > .thumbnail.tyres > img,

.product-catalog > .layout > section > .products.list > .item > a > .thumbnail > img,

.quick-search > .results > .collection > .item > a > .thumbnail > img {

    position: absolute;

    width: 100%;

    top: 0;

    left: 50%;

    transform: translateX( -50% );

}

.product-catalog > .layout > section > .products.grid > .item > a > .thumbnail.rims::after,

.product-catalog > .layout > section > .products.grid > .item > a > .thumbnail.batteries::after,

.product-catalog > .layout > section > .products.grid > .item > .thumbnail.rims::after,

.product-catalog > .layout > section > .products.grid > .item > .thumbnail.batteries::after,

.product-catalog > .layout > section > .products.list > .item > a > .thumbnail.rims::after,

.product-catalog > .layout > section > .products.list > .item > a > .thumbnail.batteries::after {

    content: "";

    display: block;

    padding-bottom: 100%;

}

.product-catalog > .layout > section > .products.grid > .item > a > .thumbnail.rims > img,

.product-catalog > .layout > section > .products.grid > .item > a > .thumbnail.batteries > img,

.product-catalog > .layout > section > .products.grid > .item > .thumbnail.rims > img,

.product-catalog > .layout > section > .products.grid > .item > .thumbnail.batteries > img {

    position: absolute;

    width: 100%;

    top: 0;

    left: 50%;

    transform: translateX( -50% );

}

.product-catalog > .layout > section > .products.grid > .item > a > .name > .model > span,

.product-catalog > .layout > section > .products.grid > .item > .name > .model > span,

.product-catalog > .layout > section > .products.list > .item > .name > .model > a > span,

.quick-search > .results > .collection > .item > .name > .model > a > span {

    text-transform: uppercase;

    display: block;

}

.product-catalog > .layout > section > .products.grid > .item > a > .name > .model:hover {

    color: var( --accent-color );

}

.product-catalog > .layout > section > .products.list > .item > .name > .model > a,

.quick-search > .results > .collection > .item > .name > .model > a {

    text-decoration: none;

    color: var( --black-color );

    outline: none;

}

.product-catalog > .layout > section > .products.list > .item > .name > .model > a:hover,

.quick-search > .results > .collection > .item > .name > .model > a:hover {

    color: var( --accent-color );

}

.product-catalog > .layout > section > .products.grid > .item > a > .name > .model,

.product-catalog > .layout > section > .products.grid > .item > .name > .model,

.product-catalog > .layout > section > .products.list > .item > .name > .model,

.quick-search > .results > .collection > .item > .name > .model {

    color: var( --black-color );

    font-size: 1.2em;

    font-weight: 600;

}

.product-catalog > .layout > section > .products.list > .item > .name,

.quick-search > .results > .collection > .item > .name {

    text-align: left;

    flex: 1;

}

.product-catalog > .layout > section > .products.grid > .item > a > .name > .size,

.product-catalog > .layout > section > .products.grid > .item > .name > .size,

.product-catalog > .layout > section > .products.list > .item > .name > .size,

.quick-search > .results > .collection > .item > .name > .size {

    color: var( --black-color );

}

.product-catalog > .layout > section > .products.grid > .item > a > .name > div:not( :last-child ),

.product-catalog > .layout > section > .products.grid > .item > .name > div:not( :last-child ),

.product-catalog > .layout > section > .products.list > .item > .name > div:not( :last-child ),

.quick-search > .results > .collection > .item > .name > div:not( :last-child ) {

    margin-bottom: 0.3em;

}

.product-catalog > .layout > section > .products.grid > .item > a > .price,

.product-catalog > .layout > section > .products.grid > .item > .price,

.product-catalog > .layout > section > .products.list > .item > .price > .product-price,

.quick-search > .results > .collection > .item > .price > .product-price,

.single-product > .layout > section > .product > .data > .price {

    color: var( --accent-color );

    font-size: 1.2em;

    font-weight: 600;

}

.single-product > .layout > section > .product > .data > .price {

    position: relative;

}

.single-product > .layout > section > .product > .data > .price > .tooltip {

    position: absolute;

    pointer-events: none;

    left: 0%;

    bottom: 120%;

    border-radius: 0.3rem;

    color: white;

    font-size: 0.9rem;

    padding: 0.1rem 0.5rem;

    opacity: 0;

    background-color: var(--accent-color);

    font-weight: 400;

    transition: 0.1s;
    
}

.single-product > .layout > section > .product > .data > .price:hover > .tooltip {

    opacity: 1;

}

.product-catalog > .layout > section > .products.list > .item > .price > .product-price,

.quick-search > .results > .collection > .item > .price > .product-price {

    margin-top: 0.5em;

}

.product-catalog > .layout > section > .products.list > .item > .price > button,

.quick-search > .results > .collection > .item > .price > button {

    line-height: 0;

    font-size: 16px;

    background-color: var( --accent-color );

    border-radius: 0.3em;

    border: none;

    color: var( --white-color );

    padding: 10px;

    outline: none;

}

.product-catalog > .layout > section > .products.list > .item > .price > button:hover,

.quick-search > .results > .collection > .item > .price > button:hover {

    color: var( --black-color );

    background-color: var( --accent-focus-color );

    cursor: pointer;

}

.product-catalog > .layout > section > .products.list > .item > .features-properties,

.quick-search > .results > .collection > .item > .features-properties {

    flex: 2;

}

.product-catalog > .layout > section > .products.grid > .item > a > .features-properties > div:not( :last-child ),

.product-catalog > .layout > section > .products.grid > .item > .features-properties > div:not( :last-child ),

.product-catalog > .layout > section > .products.list > .item > .features-properties > div:not( :last-child ),

.quick-search > .results > .collection > .item > .features-properties > div:not( :last-child ) {

    margin-bottom: 0.5em;

}

.product-props > .prop {

    position: relative;

}

.product-props > .prop:hover .props-tooltip {

    opacity: 0.9;

}

.product-props > .prop > .props-tooltip,

.feature-tooltip {

    position: absolute;

    top: -0.5rem;

    left: 50%;

    transform: translate( -50%, -100% );

    pointer-events: none;

    width: 100vw;

    max-width: 128px;

    opacity: 0;

    background-color: var( --accent-color );

    border-radius: 0.3rem;

    padding: 0.2rem;

    font-size: 0.8rem;

    color: var( --white-color );

    z-index: 1;

    font-weight: 300;

    font-style: normal;

    text-transform: none;

    line-height: normal;

}

.product-catalog > .layout > section > .products.grid > .item > a > .features-properties > .features,

.product-catalog > .layout > section > .products.grid > .item > a > .features-properties > .properties,

.product-catalog > .layout > section > .products.grid > .item > .features-properties > .features,

.product-catalog > .layout > section > .products.grid > .item > .features-properties > .properties,

.product-catalog > .layout > section > .products.list > .item > .features-properties > .features,

.quick-search > .results > .collection > .item .features,

.quick-search > .results > .collection > .item .properties,

.product-catalog > .layout > section > .products.list > .item > .features-properties > .properties,

.quick-search > .results > .collection > .item > .features-properties > .properties,

.product-props {

    display: flex;

    justify-content: center;

    flex-wrap: nowrap;

    flex-direction: row;

    align-items: center;

}

.single-product > .layout > section > .product > .data > .features,

.single-product > .layout > section > .product > .data > .properties,

.product-props > .prop {

    display: flex;

    flex-wrap: nowrap;

    flex-direction: row;

    align-items: center;

    justify-content: flex-start;

}

.single-product > .layout > section > .product > .data > .properties { text-align: center; }

.product-catalog > .layout > section > .products.grid > .item > a > .features-properties > .features > div:not( :last-child ),

.product-catalog > .layout > section > .products.grid > .item > a > .features-properties > .properties > .icon:not( :last-child ),

.product-catalog > .layout > section > .products.grid > .item > .features-properties > .features > div:not( :last-child ),

.product-catalog > .layout > section > .products.grid > .item > .features-properties > .properties > .icon:not( :last-child ),

.quick-search > .results > .collection > .item .properties > .icon:not( :last-child ),

.quick-search > .results > .collection > .item .properties > div:not( :last-child ),

.single-product > .layout > section > .product > .data > .properties > .icon:not( :last-child ),

.product-catalog > .layout > section > .products.list > .item > .features-properties > .features > div:not( :last-child ),

.quick-search > .results > .collection > .item .features > div:not( :last-child ),

.single-product > .layout > section > .product > .data > .features > div:not( :last-child ),

.product-catalog > .layout > section > .products.list > .item > .features-properties > .properties > .icon:not( :last-child ),

.quick-search > .results > .collection > .item > .features-properties > .properties > .icon:not( :last-child ),

.product-props > .prop > .icon {

    margin-right: 5px;

}

.product-catalog > .layout > section > .products.grid > .item > a > .features-properties > .properties > .value:not( :last-child ),

.product-catalog > .layout > section > .products.grid > .item > .features-properties > .properties > .value:not( :last-child ),

.product-catalog > .layout > section > .products.list > .item > .features-properties > .properties > .value:not( :last-child ),

.quick-search > .results > .collection > .item > .features-properties > .properties > .value:not( :last-child ),

.quick-search > .results > .collection > .item .properties > .value:not( :last-child ),

.single-product > .layout > section > .product > .data > .properties > .value:not( :last-child ),

.product-props > .prop:not( :last-child ) {

    margin-right: 15px;

}

.product-catalog > .layout > section > .products.grid > .item > a > .features-properties > .features > .uppercase,

.product-catalog > .layout > section > .products.grid > .item > .features-properties > .features > .uppercase,

.product-catalog > .layout > section > .products.list > .item > .features-properties > .features > .uppercase,

.quick-search > .results > .collection > .item .features > .uppercase,

.single-product > .layout > section > .product > .data > .features > .uppercase,

.product-props > .prop > .value.uppercase {

    text-transform: uppercase;

}   

.product-catalog > .layout > section > .products.grid > .item > a > .features-properties > .features > .feature:hover .feature-tooltip,

.product-catalog > .layout > section > .products.grid > .item > .features-properties > .features > .feature:hover .feature-tooltip,

.product-catalog > .layout > section > .products.list > .item > .features-properties > .features > .feature:hover .feature-tooltip,

.quick-search > .results > .collection > .item .features > .feature:hover .feature-tooltip,

.single-product > .layout > section > .product > .data > .features > .feature:hover .feature-tooltip {

    opacity: 0.9;

}

.product-catalog > .layout > section > .products.grid > .item > a > .features-properties > .features > .feature,

.product-catalog > .layout > section > .products.grid > .item > .features-properties > .features > .feature,

.product-catalog > .layout > section > .products.list > .item > .features-properties > .features > .feature,

.quick-search > .results > .collection > .item .features > .feature,

.single-product > .layout > section > .product > .data > .features > .feature {

    position: relative;

    display: flex;

    padding: 4px;

    font-size: 11px;

    width: 35px;

    height: 35px;

    border-radius: 50%;

    background: var( --secondary-text-color );

    color: var( --white-color );

    font-style: italic;

    font-weight: bold;

    align-items: center;

    justify-content: center;

    text-align: center;

    line-height: 1;

}

.product-catalog > .layout > section > .products.grid > .item > a > .features-properties > .properties > .icon,

.product-catalog > .layout > section > .products.grid > .item > .features-properties > .properties > .icon,

.product-catalog > .layout > section > .products.list > .item > .features-properties > .properties > .icon,

.quick-search > .results > .collection > .item .properties > .icon,

.quick-search > .results > .collection > .item > .features-properties > .properties > .icon,

.single-product > .layout > section > .product > .data > .properties > .icon,

.product-props > .prop > .icon {

    color: var( --secondary-text-color );

    line-height: 0;

    font-size: 20px;

}

.product-catalog > .layout > section > .products.grid > .item > a > .features-properties > .properties > .value,

.product-catalog > .layout > section > .products.grid > .item > .features-properties > .properties > .value,

.product-catalog > .layout > section > .products.list > .item > .features-properties > .properties > .value,

.quick-search > .results > .collection > .item .properties > .value,

.quick-search > .results > .collection > .item > .features-properties > .properties > .value,

.single-product > .layout > section > .product > .data > .properties > .value,

.product-props > .prop > .value {

    position: relative;

    color: var( --white-color );

    font-size: 12px;

    width: 18px;

    height: 18px;

    z-index: 1;

}

.product-catalog > .layout > section > .products.grid > .item > a > .features-properties > .properties > .value-numeric,

.product-catalog > .layout > section > .products.grid > .item > .features-properties > .properties > .value-numeric,

.single-product > .layout > section > .product > .data > .properties > .value-numeric,

.quick-search > .results > .collection > .item .properties > .value-numeric,

.product-props > .prop > .value-numeric {

    position: relative;
    
    color: white;
    
    font-weight: 500;
    
    font-size: 12px;
    
    padding: 1px 5px;
    
    border-radius: 4px;
    
    z-index: 1;
    
    background-color: var(--secondary-text-color );

}


.product-catalog > .layout > section > .products.grid > .item > a > .features-properties > .properties > .value::after,

.product-catalog > .layout > section > .products.grid > .item > .features-properties > .properties > .value::after,

.product-catalog > .layout > section > .products.list > .item > .features-properties > .properties > .value::after,

.quick-search > .results > .collection > .item > .features-properties > .properties > .value::after,

.quick-search > .results > .collection > .item .properties > .value::after,

.single-product > .layout > section > .product > .data > .properties > .value::after,

.product-props > .prop > .value::after {

    position: absolute;

    content:"";

    top: 50%;

    right: -6.5px;

    width: 12.5px;

    height: 12.5px;

    background-color: inherit;

    transform-origin: center;

    transform: translateY( -50% ) rotate( 45deg );

    z-index: -1;

}

.property-green-lighter { background-color: #bfd630; }

.property-green-light { background-color: #00bb30; }

.property-green-dark { background-color: #00a652; }

.property-blue-lighter { background-color: #57a8df; }

.property-blue-light { background-color: #007bbe; }

.property-blue-dark { background-color: #015daa; }

.rim-properties > div:nth-child( 2 ):not(:last-child) {

    margin-right: 1em!important;

}

.filter-open { display: block; }

.filter-close { display: none; }

.product-catalog > .layout > aside > .widget > .filter > .container > .content > div:not( :last-child ) {

    margin-bottom: 0.5em;

}

.product-catalog > .layout > aside > .widget > .filter > .container > .content label.select {

    width: 100%;

}

.product-catalog > .layout > aside > .widget > .filter > .container > .content select {

    width: inherit;
    
}

.product-catalog > .layout > aside > .widget > .filter > .container > .content > .select-filter > .title {

    display: flex;

    align-items: center;

    justify-content: space-between;

    flex-direction: row;

    flex-wrap: nowrap;

    margin-bottom: 0.5em;

}

.product-catalog > .layout > aside > .widget > .filter > .container > .content > .select-filter > .title > .name {

    font-size: 0.9em;

}

.product-catalog > .layout > aside > .widget > .filter > .container > .content > .select-filter > .title > .info {

    position: relative;

    color: var( --secondary-text-color );

    line-height: 0;

}

.product-catalog > .layout > aside > .widget > .filter > .container > .content > .select-filter > .title > .info:hover {

    color: var( --accent-color );

    cursor: help;

}

.product-catalog > .layout > aside > .widget > .filter > .container > .content > .select-filter > .title > .info:hover .filter-tooltip {

    opacity: 1;

}

.filter-tooltip {

    position: absolute;

    opacity: 0;

    background-color: rgba( 0, 0, 0, 0.8 );

    color: var( --white-color );

    top: -10%;

    left: 50%;

    transform: translate( -50%, -100% );

    padding: 2px 5px;

    border-radius: 0.3em;

    font-size: 13px;

    text-align: center;

    pointer-events: none;

    width: max-content;

    -moz-width: max-content;

    max-width: 156px;

    line-height: 1.5;

    z-index: 9;

}

.product-catalog > .layout > aside > .widget > .filter > .container > .seasons > label:not( :last-child ),

.product-catalog > .layout > aside > .widget > .filter > .container > .grip-index > label:not( :last-child ),

.product-catalog > .layout > aside > .widget > .filter > .container > .voltages > label:not( :last-child ) {

    margin-bottom: 5px;

}

.product-catalog > .layout > aside > .widget > .filter > .container > .content > .attribute-filter {

    background-color: var( --white-color );

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

    border-radius: 0.3em;

}

.product-catalog > .layout > aside > .widget > .filter > .container > .content > .attribute-filter > input {

    width: 100%;

    border: none;

    border-bottom: 1px solid var( --details-color );

    border-radius: 0;

    outline: none;

    padding: 1em;

}  

.product-catalog > .layout > aside > .widget > .filter > .container > .content > .attribute-filter > .collection-container {

    position: relative;

}

.product-catalog > .layout > aside > .widget > .filter > .container > .content > .attribute-filter > .collection-container > .collection {

    position: relative;

    padding: 1em;

    padding-bottom: 1.5em;

    max-height: 256px;

    overflow-y: scroll;

}

.product-catalog > .layout > aside > .widget > .filter > .container > .content > .attribute-filter > .collection-container > .collection > label:not( :last-child ) {

    border-bottom: 1px solid var( --details-color );

    margin-bottom: 0.5rem;

    padding-bottom: 0.5rem;

}

.product-catalog > .layout > aside > .widget > .filter > .container > .content > .attribute-filter > .collection-container::after{

    content: "";

    pointer-events: none;

    border-bottom-left-radius: 0.3em;

    border-bottom-right-radius: 0.3em;

    position: absolute;

    width: 100%;

    height: 2em;

    bottom: 0;

    left: 0;

    background: rgb(255,255,255);

    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(0,212,255,0) 100%);

    z-index: 1;

}

.product-catalog > .layout > aside > .widget > .clear-filters { text-align: right; }

.product-catalog > .layout > aside > .widget > .clear-filters > button {

    background: transparent;

    border: none;

    text-decoration: underline;

    outline: none;

}

.product-catalog > .layout > aside > .widget > .clear-filters > button:hover {

    color: var( --accent-color );

    cursor: pointer;

}

.single-product > .layout > section > div:not( :last-child ) {

    margin-bottom: 2em;

}

.single-product > .layout > section > .product {

    display: grid;

    grid-gap: 2em;

    grid-template-columns: 0.5fr 1fr;

}

.single-product > .layout > section > .description > div:not( :last-child ) {

    margin-bottom: 1em;

}

.single-product > .layout > section > .description > h3,

.product-specifications > h3 {

    margin-bottom: 1em;

    padding-bottom: 0.5em;

    font-weight: 500;

    border-bottom: 1px solid var( --details-color );

}

.single-product > .layout > section > .description > .paragraph {

    line-height: 2;

}   

.single-product > .layout > section > .product > .image {

    position: relative;
    
    border: 1px solid #eee;

    border-radius: 0.3em;

    width: 100%;

}

.single-product > .layout > section > .product > .image::after {

    content: "";

    display: block;

    padding-bottom: 100%;

}

.single-product > .layout > section > .product > .image > img {

    position: absolute;

    width: 90%;

    height: 90%;

    top: 50%;

    left: 50%;

    transform: translate( -50%, -50% );

    object-fit: contain;

}

.single-product > .layout > section > .product > .data > div:not( :last-child ) {

    margin-bottom: 1em;

}

.single-product > .title > h2 {

    font-weight: 500;

}

.single-product > .title > h2 > span {

    font-weight: 300;

}

.single-product > .title {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

    justify-content: space-between;

    width: 100%;

}

.single-product > .title > span.product_id,

.single-product > .layout > section > .product > .data > .mobile-title > .product_id {

    font-size: 0.9em;

    color: var( --secondary-text-color );

    font-weight: 500;

}

.single-product > .title > span.product_id {

    margin-left: 1rem;

}

.single-product > .layout > section > .product > .data > .stock {

    display: flex;

    align-items: center;

    flex-wrap: nowrap;

    flex-direction: row;

}

.single-product > .layout > section > .product > .data > .stock > span.icon {

    margin-right: 5px;

    font-size: 20px;

    line-height: 0;

}

.single-product > .layout > section > .product > .data > .stock > span.notice {

    font-weight: 500;

}

.color-out-of-stock { color: #c92323; }

.color-in-stock { color: #558b2f; }

.single-product > .layout > section > .product > .data > .add-to-compare > button {
    
    display: flex;

    align-items: center;

    flex-direction: row;

    flex-wrap: nowrap;

    justify-content: center;

    padding: 10px 20px;

    border: none;

    outline: none;

    border-radius: 0.3em;

    background-color: var( --secondary-text-color );

    color: var( --white-color );

}

.single-product > .layout > section > .product > .data > .add-to-compare > button:hover {

    cursor: pointer;

    background-color: var( --accent-focus-color );

    color: var( --black-color );

}

.single-product > .layout > section > .product > .data > .add-to-compare > button > span {

    margin-left: 10px;

    font-size: 20px;
    
    line-height: 0;

}

.single-product > .layout > section > .product > .data > .add-to-cart,

.single-product > .layout > section > .product > .data > .add-to-cart > .quantity-content {

    display: flex;

    align-items: center;

    flex-wrap: nowrap;

    flex-direction: row;

}

.single-product > .layout > section > .product > .data > .add-to-cart > div:not( :last-child ) {

    margin-right: 1em;

}

.single-product > .layout > section > .product > .data > .add-to-cart > .quantity-content > .up > button:hover,

.single-product > .layout > section > .product > .data > .add-to-cart > .quantity-content > .down > button:hover {

    cursor: pointer;

    background-color: var( --accent-focus-color );

    color: var( --black-color );

}   

.single-product > .layout > section > .product > .data > .add-to-cart > .quantity-content > div:not( :last-child ) {

    margin-right: 1em;

}

.single-product > .layout > section > .product > .data > .add-to-cart > .add > button,

.single-product > .layout > section > .product > .data > .add-to-cart > .quantity-content > .up > button,

.single-product > .layout > section > .product > .data > .add-to-cart > .quantity-content > .down > button {
    
    display: flex;

    align-items: center;

    flex-direction: row;

    flex-wrap: nowrap;

    justify-content: center;

    padding: 10px 20px;

    border: none;

    outline: none;

    border-radius: 0.3em;

}

.single-product > .layout > section > .product > .data > .add-to-cart > .add > button {

    background-color: #0090ff;

    color: var( --white-color );

    text-transform: uppercase;

    font-weight: 600;

}

.single-product > .layout > section > .product > .data > .add-to-cart > .add > button:hover {

    background-color: black;

    cursor: pointer;

}

.single-product > .layout > section > .product > .data > .add-to-cart > .add > button:disabled {

    opacity: 0.5;

}

.single-product > .layout > section > .product > .data > .add-to-cart > .add > button:disabled:hover {

    background-color: #0090ff;

    cursor: not-allowed;

}

.single-product > .layout > section > .product > .data > .add-to-cart > .add > button > span {

    margin-left: 10px;

    font-size: 20px;
    
    line-height: 0;

}

.single-product > .layout > section > .product > .data > .add-to-cart > .quantity-content > .quantity > input {

    padding: 0.5rem 20px;

    border: none;

    outline: none;

    border-radius: 0.3em;

    box-shadow: 0 0 1em 0.2em rgb(0 0 0 / 10%);

    width: 100%;

    max-width: 78px;

    font-size: 1.1rem;

    text-align: center;

    -moz-appearance: textfield;

}

.single-product > .layout > section > .product > .data > .add-to-cart > .quantity-content > .quantity > input::-webkit-outer-spin-button,
.single-product > .layout > section > .product > .data > .add-to-cart > .quantity-content > .quantity > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.single-product > .layout > section > .product > .data > .add-to-cart > .quantity-content > .down > button {

    background-color: var( --black-color );

    color: var( --white-color );

}

.single-product > .layout > section > .product > .data > .add-to-cart > .quantity-content > .up > button {

    color: var( --white-color );

    background-color: var( --accent-color );

}

.single-product > .layout > section > .product > .data > .mobile-title {

    display: none;

}

.single-product > .layout > section > .product > .data > .mobile-title > div:not( :last-child ) {

    margin-bottom: 5;

}

.single-product > .layout > section > .product > .data > .mobile-title > .manufacturer,

.single-product > .layout > section > .product > .data > .mobile-title > .model {

    font-weight: 600;

    font-size: 20px;
        
}

.single-product > .layout > section > .product > .data > .mobile-title > .size {

    font-weight: 400;
        
}

.index-checkbox > .check {

    margin-right: 10px;

}

.index-checkbox > .index-icon {

    line-height: 0;

    color: var( --secondary-text-color );

}

.index-colorful-a { width: 100%; max-width: 20px; background-color: var( --indexed-label-col-a ); }

.index-colorful-b { width: 100%; max-width: 30px; background-color: var( --indexed-label-col-b ); }

.index-colorful-c { width: 100%; max-width: 40px; background-color: var( --indexed-label-col-c ); }

.index-colorful-d { width: 100%; max-width: 50px; background-color: var( --indexed-label-col-d ); }

.index-colorful-e { width: 100%; max-width: 60px; background-color: var( --indexed-label-col-e ); }

.index-colorful-f { width: 100%; max-width: 70px; background-color: var( --indexed-label-col-f ); }

.index-colorful-g { width: 100%; max-width: 80px; background-color: var( --indexed-label-col-g ); }

.index-colorful-p { width: 100%; max-width: 80px; background-color: var( --indexed-label-col-p ); }

.index-colorful-x { width: 100%; max-width: 80px; background-color: var( --indexed-label-col-x ); }

.index-colorful-o { width: 100%; max-width: 80px; background-color: var( --indexed-label-col-o ); }

.index-checkbox > .name {

    position: relative;

    color: #FFF;

    padding: 2px 5px;

    height: 24px;

    font-weight: 600;

}

.index-checkbox > .index-colorful-a::after { border-left: 12px solid var( --indexed-label-col-a ); }

.index-checkbox > .index-colorful-b::after { border-left: 12px solid var( --indexed-label-col-b ); }

.index-checkbox > .index-colorful-c::after { border-left: 12px solid var( --indexed-label-col-c ); }

.index-checkbox > .index-colorful-d::after { border-left: 12px solid var( --indexed-label-col-d ); }

.index-checkbox > .index-colorful-e::after { border-left: 12px solid var( --indexed-label-col-e ); }

.index-checkbox > .index-colorful-f::after { border-left: 12px solid var( --indexed-label-col-f ); }

.index-checkbox > .index-colorful-g::after { border-left: 12px solid var( --indexed-label-col-g ); }

.index-checkbox > .index-colorful-p::after { border-left: 12px solid var( --indexed-label-col-p ); }

.index-checkbox > .index-colorful-x::after { border-left: 12px solid var( --indexed-label-col-x ); }

.index-checkbox > .index-colorful-o::after { border-left: 12px solid var( --indexed-label-col-o ); }

.index-checkbox > .name::after {

    position: absolute;

    content: "";

    top: 0;

    right: -12px;

    width: 0; 

    height: 0; 

    border-top: 12px solid transparent;

    border-bottom: 12px solid transparent;

}

.property-color-a { background-color: var( --indexed-label-col-a ); }

.property-color-b { background-color: var( --indexed-label-col-b ); }

.property-color-c { background-color: var( --indexed-label-col-c ); }

.property-color-d { background-color: var( --indexed-label-col-d ); }

.property-color-e { background-color: var( --indexed-label-col-e ); }

.property-color-f { background-color: var( --indexed-label-col-f ); }

.property-color-g { background-color: var( --indexed-label-col-g ); }

.property-color-p { background-color: var( --indexed-label-col-p ); }

.property-color-x { background-color: var( --indexed-label-col-x ); }

.property-color-o { background-color: var( --indexed-label-col-o ); }

.quick-search > .title > h2 {

    text-transform: uppercase;

}

.quick-search > .results > *:not( :last-child ) {

    margin-bottom: 2em;

}

.quick-search > .results > .collection > *:not( :last-child ) {

    margin-bottom: 1em;

}

.quick-search .item > div {

    display: flex;

    width: 100%;

    align-items: center;

    flex-direction: row;

    flex-wrap: nowrap;

}

.quick-search .item > div > *:not( :first-child ):not( :last-child ) {

    flex: 1;

}

.quick-search .item > div > *:not( :last-child ) {

    margin-right: 2em;

}

.quick-search .item > div > .product-name {

    text-align: left;

}

.quick-search .item > div > .product-image {

    width: 100%;

    max-width: 150px;

}

.quick-search .item > div > .product-image > a {

    display: block;

    position: relative;

}

.quick-search .item > div > .product-image > a::after {

    content: "";

    display: block;

    padding-bottom: 100%;

}

.quick-search .item > div > .product-image > a > img {

    position: absolute;

    width: 100%;

    height: 100%;

    top: 0;

    left: 0;

    object-fit: contain;

    border-radius: 0.3em;

}

.quick-search .item > div > .product-name > a {

    display: inline-block;

    text-decoration: none;

    outline: none;

    color: var( --black-color );

    font-weight: 500;

}

.quick-search .item > div > .product-name > a:hover {

    color: var( --accent-color );

}

.quick-search .item > div > .product-name > a > .manufacturer {

    text-transform: uppercase;

}

.quick-search .item > div > .product-name > .price {

    color: var( --accent-color );

    font-weight: 600;

}

.quick-search .item > div > .product-name > *:not( :last-child ),

.quick-search .item > div > .product-name > a > div:not( :last-child ),

.quick-search .item > div > .product-data > div:not( :last-child ) {

    margin-bottom: 8px;

}

.quick-search .item > div > .product-actions > a,

.product-catalog > .layout > section > .products.list > .item > .price > a {

    background-color: var( --accent-color );

    display: inline-block;

    line-height: 0;

    text-decoration: none;

    outline: none;

    color: var( --white-color );

    font-size: 20px;

    padding: 8px;

    border-radius: 0.3em;

}

.quick-search .item > div > .product-actions > a:hover,

.product-catalog > .layout > section > .products.list > .item > .price > a:hover {

    color: var( --black-color );

    background-color: var( --accent-focus-color );

}

.quick-search .no-results {

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

    padding: 2em;

    border-radius: 0.3em;

}

.text-product-property {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.text-product-property > div:not( :last-child ) {

    margin-right: 8px;

}

.text-product-property > div,

.text-prop {

    position: relative;
    
    color: white;
    
    font-weight: 500;
    
    font-size: 12px;
    
    padding: 1px 5px;
    
    border-radius: 4px;
    
    z-index: 1;
    
    background-color: var(--secondary-text-color );

}

.product-specifications > .list > div:not( :last-child ) {

    margin-bottom: 8px;

    padding-bottom: 8px;

    border-bottom: 1px dashed var( --details-color );

}

.product-specifications > .list > .item {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

    width: 100%;

}

.product-specifications > .list > .item > div {

    flex: 1;

}

.product-specifications > .list > .item > .name {

    font-weight: 500;

}

.product-specifications > .list > .item > .value.uppercase {

    text-transform: uppercase;

}

.mobile-filters-switch,

.close-mobile-filters {

    display: none;

}

.mobile-filters-switch > button,

.widget > .close-mobile-filters > button {

    background: none;

    line-height: 0;

    font-size: 24px;

    border: none;

    outline: none;

    color: var(--black-color );

}

.mobile-filters-switch > button {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.mobile-filters-switch > button > span {

    font-size: 0.9rem;

    margin-left: 0.5rem;

    text-transform: uppercase;

    letter-spacing: 1px;

}

.widget > .sticky-close {

    display: none;

    position: fixed;

    right: 15%;

    line-height: 0;

    font-size: 1.5rem;

    border-radius: 50%;

    border: none;

    padding: 0.5rem;

    background: var(--accent-color );

    color: white;

    top: 50%;

    text-align: center;

}

.mobile-filters-state {

    display: block!important;

}

.single-product > .layout > section > .product > .data > .product-action-notification {

    padding: 1em;

    border-radius: 0.3em;

}

.single-product > .layout > section > .product > .data > .product-action-notification.success {

    border: 1px solid #c8e6c9;

    background-color: #e8f5e9;

    color: #1b5e20;

}

.single-product > .layout > section > .product > .data > .product-action-notification.error {

    border: 1px solid #f8bbd0;
    
    background-color: #fce4ec;
    
    color: #880e4f;

}

.single-product > .layout > section > .product > .data > .delivery > div:not( :last-child ) {

    margin-bottom: 1rem;

}

.single-product > .layout > section > .product > .data > .delivery > .delivery_option {

    position: relative;

    display: inline-flex;

    width: 100%;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.single-product > .layout > section > .product > .data > .delivery > .delivery_option > span.tooltip {

    position: absolute;

    pointer-events: none;

    left: 0%;

    bottom: 120%;

    border-radius: 0.3rem;

    color: white;

    font-size: 0.9rem;

    padding: 0.1rem 0.5rem;

    opacity: 0;

    background-color: var(--accent-color);

    transition: 0.1s;

}

.single-product > .layout > section > .product > .data > .delivery > .delivery_option:hover > span.tooltip {

    opacity: 1;

}

.single-product > .layout > section > .product > .data > .delivery > .delivery_option > div {

    display: inline-flex;

    width: 100%;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.single-product > .layout > section > .product > .data > .delivery > .delivery_option > div > .icon {

    line-height: 0;

    margin-right: 0.5rem;

}

.single-product > .layout > section > .product > .data > .delivery > .delivery_option > .title.accented {

    color: black;
    
    font-weight: 600;

}

.season-icons {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    justify-content: center;

    align-items: center;

}

.season-icons > img {

    max-width: 28px;

}

.season-icons > img:not( :last-child ) {

    margin-right: 0.5rem;

}

.product-catalog > .layout > aside > .widget > .filter > .container > .content.price > .output > input {

    width: 100%;

    border: none;

    box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);

    padding: 0.5rem;

    border-radius: 0.3rem;

    outline: none;

}   

.product-catalog > .layout > aside > .widget > .filter > .container > .content.price > .output {

    display: inline-flex;

}

.product-catalog > .layout > aside > .widget > .filter > .container > .content.price > .output > input:not( :last-child ) {

    margin-right: 1rem;

}

.product-catalog > .layout > aside > .widget > .filter > .container > .content.price > .range {

    margin-right: 0.5rem;

    margin-left: 0.5rem;

}

.catalog-item-actions {

    display: inline-flex;

    /* width: 100%; */

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.catalog-item-actions > *:not( :last-child ) {

    margin-right: 0.5rem;

}

button.add-to-cart-catalog,

button.view-item-catalog {

    outline: none;

    color: var( --white-color );

    background-color: var( --accent-color );

    border: none;

    display: inline-block;

    padding: 0.6rem;

    font-weight: 600;

    border-radius: 0.3rem;

    line-height: 0;

    font-size: 1.1rem;

}

button.view-item-catalog {

    font-size: 1rem;

    line-height: 0;

}

button.add-to-cart-catalog {

    flex: 1;

}

button.view-item-catalog {

    flex: 0;

}

button.add-to-cart-catalog:hover,

button.view-item-catalog:hover {

    cursor: pointer;

    background-color: var( --accent-focus-color );

    color: var( --black-color );

}

button.add-to-cart-catalog:disabled {

    pointer-events: none;

    opacity: 0.5;

}

/* span.MuiSlider-track.css-1gv0vcd-MuiSlider-track {

    background-color: var( --accent-color );

    border: 1px solid var( --accent-color );

}

.css-eg0mwd-MuiSlider-thumb {

    background-color: var( --accent-color )!important;

}

.css-14pt78w-MuiSlider-rail {

    background-color: var( --details-hover-color )!important;

} */

.modal-container {

    position: fixed;

    top: 0;

    left: 0;

    width: 100%;

    height: 100vh;

    background-color: rgba( 0, 0, 0, 0.2 );

    z-index: 991;

    animation-name: modalBackground;

    animation-duration: 0.3s;

    animation-timing-function: ease;

    animation-fill-mode: forwards;

}

@keyframes modalBackground {
    
    0% { background-color: rgba( 0, 0, 0, 0 ); }

    100% { background-color: rgba( 0, 0, 0, 0.2 ); }

}

.modal-content {

    background-color: #FFF;

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

    border-radius: 0.3rem;

    z-index: 992;

}

@keyframes modalContent {
    
    0% { opacity: 0; top: 40%; }

    100% { opacity: 1; top: 50%; }

}

.modal-outline {

    position: absolute;

    top: 50%;

    left: 50%;

    transform: translate( -50%, -50% );

    width: 100%;

    max-width: 768px;

    padding-right: 1rem;

    padding-left: 1rem;

    animation-name: modalContent;

    animation-duration: 0.2s;

    animation-timing-function: ease;

    animation-fill-mode: forwards;

}

.modal-content > div {

    padding: 1rem;

}

.modal-header {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

    width: 100%;

    background-color: var( --accent-color );

    border-top-right-radius: 0.3rem;

    border-top-left-radius: 0.3rem;

}

.modal-header > .modal-title {

    flex: 1;

    color: var( --white-color );

    font-weight: 500;

}

.modal-header > .close-modal {

    line-height: 0;

}

.modal-header > .close-modal > button {

    line-height: 0;

    background: none;

    border: none;

    color: var( --white-color );

    outline-color: transparent;

}

.modal-header > .close-modal > button > svg {

    font-size: 1.3rem;

}

.modal-header > .close-modal > button:hover {

    cursor: pointer;
    
}

.modal-footer {

    text-align: right;

}

.modal-footer-actions {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.modal-footer-actions > *:not( :last-child ) {

    margin-right: 1rem;

}

.modal-body > .field-group > input,

.modal-body > .field-group > textarea {

    display: block;

    width: 100%;

    width: 100%;

    border: 2px solid var(--details-color );

    border-radius: 0.3em;

    outline: none;

    padding: 8px;

}

.modal-body > .field-group > textarea {

    height: 100px;

    max-height: 200px;

    min-height: 50px;

    resize: vertical;

}   

.modal-body > .field-group > label {

    margin-bottom: 0.3rem;

    display: block;

    font-size: 0.9rem;

}

.modal-body > .field-group:not( :last-child ) {

    margin-bottom: 1rem;

}

.modal-footer > .modal-footer-actions > .submit {

    background-color: var(--accent-color );

    color: #FFF;

    border: none;

    outline: none;

    display: inline-block;

    padding: 1em;

    border-radius: 0.3rem;

    font-weight: 600;

    outline: none;

}

.modal-footer > .modal-footer-actions > .cancel {

    background-color: var( --secondary-text-color );

    color: #FFF;

    border: none;

    outline: none;

    display: inline-block;

    padding: 1em;

    border-radius: 0.3rem;

    font-weight: 600;

    outline: none;

}

.modal-footer > .modal-footer-actions > button:hover {

    cursor: pointer;

    background-color: var( --accent-focus-color );

    color: var( --black-color );

}

.modal-body > .modal-errors {

    background-color: #ffebee;

    border: 1px solid #ffcdd2;

    color: #b71c1c;

    padding: 1rem;

    font-size: 0.9rem;

    font-weight: 500;

    border-radius: 0.3rem;

    margin-bottom: 1rem;

}

.modal-body > .modal-errors > div:not( :last-child ) {

    margin-bottom: 0.3rem;

}

.cart-added-notice-list {

    top: 1rem;

    left: 0;

    animation-name: cartAddedEffectList;

    animation-duration: 0.3s;

    animation-timing-function: ease;

    animation-fill-mode: forwards;

}

.cart-added-notice {

    bottom: 0;

    left: 50%;

    transform: translateX(-50%);

    animation-name: cartAddedEffect;

    animation-duration: 0.3s;

    animation-timing-function: ease;

    animation-fill-mode: forwards;

}

.cart-added-notice,

.cart-added-notice-list {

    position: absolute;

    z-index: 1;

    background: #4CAF50;

    color: #FFF;

    font-size: 0.9rem;

    font-weight: 500;

    padding: 0.3rem 1rem;

    border-radius: 0.3rem;

}

@keyframes cartAddedEffect {
    
    0% { opacity: 0; bottom: -1rem; }

    100% { opacity: 1; bottom: 0rem; }

}

@keyframes cartAddedEffectList {
    
    0% { opacity: 0; left: 0; }

    100% { opacity: 1; left: 1rem; }

}

.no-products-found {

    display: inline-flex;

    border: 1px dashed var( --details-color );

    padding: 1rem;

    width: 100%;

    border-radius: 0.3rem;

    font-weight: 500;

    align-items: center;

    flex-wrap: nowrap;

    flex-direction: row;

}

.no-products-found > span {

    line-height: 0;

    color: var( --accent-color );

    margin-right: 1rem;

    font-size: 1.8rem;

}

.catalog-item-actions > .quantity-controller > *:not( :last-child ) {

    margin-right: 0.5rem;

}

.catalog-item-actions > .quantity-controller > button {

    background-color: var( --footer-color );

    border: none;

    width: 24px;

    height: 24px;

    border-radius: 50%;

    color: var( --white-color );

    font-size: 1rem;

}

.catalog-item-actions > .quantity-controller > button:hover {

    background-color: var( --accent-color );

    cursor: pointer;

}

.catalog-item-actions > .quantity-controller > input {

    border: none;

    box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);

    padding: 0.3rem 0.5rem;

    border-radius: 0.3rem;

    outline: none;

    width: 100%;
    
    max-width: 3rem;

    font-size: 1.1rem;

    text-align: center;

    -moz-appearance: textfield;

}

.catalog-item-actions > .quantity-controller > input::-webkit-outer-spin-button,
.catalog-item-actions > .quantity-controller > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity-controller {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.product-catalog > .layout > section > .products.grid > .item > .stocks > .availability,

.product-catalog > .layout > section > .products.grid > .item > a > .stocks > .availability,

.product-catalog > .layout > section > .products.list > .item > .price > .stocks > .availability,

.quick-search .availability {

    /* text-transform: uppercase; */

    font-weight: 500;

    font-size: 0.85rem;

}

.product-catalog > .layout > section > .products.grid > .item > .stocks > .availability > .value.accented,

.product-catalog > .layout > section > .products.grid > .item > a > .stocks > .availability > .value.accented,

.product-catalog > .layout > section > .products.list > .item > .price > .stocks > .availability > .value.accented,

.quick-search .availability > .value.accented {

    font-weight: 600;

}

.product-catalog > .layout > section > .products.grid > .item > .stocks > .availability > .value.accented.alerted,

.product-catalog > .layout > section > .products.grid > .item > a > .stocks > .availability > .value.accented.alerted,

.product-catalog > .layout > section > .products.list > .item > .price > .stocks > .availability > .value.accented.alerted,

.quick-search .availability > .value.accented.alerted {

    color: #ff5722;

}   

.product-catalog > .layout > section > .products.grid > .item > .stocks > .availability > div:not( :last-child ),

.product-catalog > .layout > section > .products.grid > .item > a > .stocks > .availability > div:not( :last-child ),

.product-catalog > .layout > section > .products.list > .item > .price > .stocks > .availability > div:not( :last-child ),

.quick-search .availability > div:not( :last-child ) {

    margin-bottom: 0.3rem;

}


.product-catalog > .layout > section > .products.list > .item > .price > .stocks > .availability > div:last-child,

.quick-search .availability > div:last-child {

    margin-bottom: 0.5rem;

}

.single-product .montage-notification {

    background-color: #F0F4C3;

    color: #827717;

    padding: 1rem;

    font-size: 0.9rem;

    border-radius: 0.3rem;

}

.single-product .montage-notification > *:not( :last-child ) {

    margin-bottom: 0.1rem;

}

.single-product .montage-notification > .title {

    font-weight: 500;

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

}

.single-product .montage-notification > .title > svg {

    margin-right: 0.25rem;

}


.single-product .montage-notification > .description > a {

    font-weight: 500;

    color: #827717;

}