.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    align-items: stretch; /* Ensure all children stretch to the tallest */
    grid-auto-rows: minmax(100px, auto); /* Ensure consistent row height */
}

.container > * {
    align-self: stretch; 
    display: flex;
    flex-direction: column;
}

.input {
    display: none;
}

.input:checked + .content {
    border-color: black;
    background-color: transparent;
    pointer-events: none;
    border-color: #c92423;
}

.input:checked + .content .icon,

.input:checked + .content .name {
    color: #c92423;
}

.input:disabled + .content {
    opacity: 0.5;
    pointer-events: none;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 1rem;
    align-items: flex-start;
    border: 1px solid #d2d2d2;
    background-color: #f5f5f5;
    border-radius: 0.3rem;
    padding: 1rem;
    cursor: pointer;
}

.content:hover {
    border-color: black;
    background-color: #fafafa;
}

.name {
    font-weight: 500;
}

.description {
    font-size: 0.8rem;
    color: #666;
}

.notice {
    font-size: 0.75rem;
    color: #c92423;
    font-weight: 500;
}

.icon {
    line-height: 0;
    /* padding-right: 0.5rem;
    padding-left: 0.5rem; */
}

.button {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.button > label {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.text > *:not(:last-child) {
    margin-bottom: 0.25rem;
}

@media (max-width: 768px) {
    .container {
        grid-template-columns: 1fr;
    }
}