header {

    position: sticky;

    top: 0;

    z-index: 990;

}

header .container {

    background-color: var( --white-color );

    box-shadow: 0 10px 9px 0px rgb(0 0 0 / 10%);

}

header .store-actions {

    display: flex;

    flex-direction: row;

    flex-wrap: nowrap;

    justify-content: space-between;

    align-items: center;

    padding-top: 1.5em;

    padding-bottom: 1.5em;

}

header .store-actions > div:not( :last-child ) {

    margin-right: 2em;

}

header .logotype {

    line-height: 0;

}

header .logotype img {

    width: 100%;

    max-width: 200px;

}   

header .actions > ul {

    display: flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

header .actions > ul > li:not( :last-child ) {

    margin-right: 1em;

    padding-right: 1em;

    border-right: 1px solid var( --details-color );

}

header .actions .labeled-action > a {

    display: flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

header .actions .labeled-action > a > svg {

    margin-right: 10px;

    font-size: 20px;

}

header .actions .glyphed-action {

    line-height: 0;

    font-size: 20px;

}

header .actions a {

    position: relative;

    color: var( --black-color );

    text-decoration: none;

    outline: none;

}

header .actions a:hover {

    color: var( --accent-color );

}

header .language-selector {

    position: relative;

}

header .language-selector > .selector {

    display: flex;

    align-items: center;

    flex-direction: row;

    flex-wrap: nowrap;

}

header .language-selector > .selector:hover {

    cursor: pointer;

    color: var( --accent-color );

}

header .language-selector > .selector > svg {

    font-size: 20px;

}

header .language-selector > .selector > span {

    margin-right: 10px;

    margin-left: 10px;

    text-transform: uppercase;

}

header .language-selector > .dropdown {

    position: absolute;

    background: white;

    left: 50%;

    transform: translateX(-50%);

    top: 100%;

    border-radius: 0.3em;

    padding: 1em;

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

    z-index: 998;

}

header .language-selector > .dropdown > ul > li:not( :last-child ) {

    margin-bottom: 0.5em;

}

header .language-selector > .dropdown > ul > li > button {

    background: none;

    border: none;

}

header .language-selector > .dropdown > ul > li > button:hover:not( :disabled ) {

    cursor: pointer;

    color: var( --accent-color );

}

header .mobile-navigation-action {

    margin-left: 1em;

    padding-left: 1em;

    border-left: 1px solid var( --details-color );

    line-height: 0;

    display: none;

}

header .mobile-navigation-action > button {

    background: none;

    border: none;

    font-size: 20px;

    line-height: 0;

}

header .mobile-navigation-action > button:hover {

    color: var( --accent-color );

    cursor: pointer;

}

header .mobile-navigation {

    position: fixed;

    box-shadow: 0 0 50px 50px rgb(0 0 0 / 20%);

    background-color: var( --white-color );

    width: 80%;

    min-height: 100vh;

    max-height: 100vh;

    top: 0;

    right: -80%;

    opacity: 0;

    overflow-y: scroll;

    z-index: 999;

    padding: 1.5em;

}

.open-mobile-nav {

    animation-name: openMobileNav;

    animation-duration: 0.3s;

    animation-timing-function: ease;

    animation-iteration-count: 1;

    animation-fill-mode: forwards;

}

@keyframes openMobileNav {

    0% { opacity: 0; right: -90% }

    100% { opacity: 1; right: 0% }
    
}

header .mobile-navigation > div:not( :last-child ) {

    margin-bottom: 2em;

}

header .mobile-navigation-overflow,

header .mobile-navigation-wrapper {

    position: fixed;

    height: 100vh;

    top: 0;

    left: 0;

}

header .mobile-navigation-overflow { width: 20%; }

header .mobile-navigation-wrapper { width: 100%; z-index: 999; }

header .mobile-navigation > .close {

    text-align: right;

}

header .mobile-navigation > .close > button {

    line-height: 0;

    font-size: 20px;

    background: none;

    border: none;

    outline: none;

}

header .mobile-navigation > .sale > a,

header .mobile-navigation > .contacts {

    display: inline-flex;

    width: 100%;

    align-items: center;

    justify-content: center;

    flex-direction: row;

    flex-wrap: nowrap;

    border-radius: 0.3em;

    padding: 0.8em;

    color: var( --white-color );

    font-weight: 600;

    text-transform: uppercase;

    font-size: 0.9em;

}

header .mobile-navigation > .sale > a { background-color: #ef6c00; }

header .mobile-navigation > .contacts { background-color: #008746; }

header .mobile-navigation > .sale > a > span {

    margin-left: 5px;

    line-height: 0;

    font-size: 20px;

}

header .mobile-navigation > .location > div:not( :last-child ) {

    margin-bottom: 1em;

}

header .mobile-navigation > .location > .location-name {

    font-weight: 600;

}

header .mobile-navigation > .location > .location-list > ul {

    display: inline-flex;

    align-items: center;

    flex-direction: row;

    flex-wrap: nowrap;

}

header .mobile-navigation > .location > .location-list > ul > li {

    line-height: 0;

}

header .mobile-navigation > .location > .location-list > ul > li:not( :last-child ) {

    margin-right: 1.5em;

    padding-right: 1.5em;

    border-right: 1px solid var( --details-color );

}

header .mobile-navigation > .location > .location-list > ul > li > a {

    font-size: 25px;

}

header .mobile-navigation > .business-information > ul > li:not( :last-child ) {

    margin-bottom: 0.5em;

}

header .mobile-navigation > .business-information > ul > li {

    display: grid;

    grid-template-columns: repeat( 2, 1fr );

}

header .mobile-navigation > .business-information > ul > li > .name {

    font-weight: 600;

}

header .mobile-navigation > .mobile-nav-actions > ul > li:not( :last-child ),

header .mobile-navigation > .shop > ul > li:not( :last-child ){

    margin-bottom: 0.5em;

    padding-bottom: 0.5em;

    border-bottom: 1px dashed var( --details-color );

}

header .mobile-navigation > .mobile-nav-actions > ul > li > a {

    display: grid;

    grid-template-columns: auto 1fr auto;

    align-items: center;

}

header .mobile-navigation > .mobile-nav-actions > ul > li > a > .name {

    padding-right: 1em;

    padding-left: 1em;

}

header .mobile-navigation > .mobile-nav-actions > ul > li > a > .icon {

    line-height: 0;

    font-size: 20px;

}

header .mobile-navigation > .mobile-nav-actions > ul > li > a > .counter {

    background-color: var( --accent-color );

    color: var( --white-color );

    font-size: 0.8em;

    padding: 2px 6px;

    border-radius: 0.3em;

    font-weight: 500;

}

header .navigation {

    display: flex;

    align-items: flex-end;

    justify-content: space-between;

}

header .navigation > nav > ul {

    display: inline-flex;

}

header .navigation > nav > ul > li:not( :last-child ) {

    margin-right: 0.3em;

}

header .navigation > .clearance {

    margin-left: 0.3em;

}

header .navigation > nav > ul > li > a {

    display: flex;

    align-items: center;

    background-color: var( --details-color );

    padding: 1em;

    color: var( --black-color );

    text-transform: uppercase;

    text-decoration: none;

    border-top-right-radius: 0.3em;

    border-top-left-radius: 0.3em;

}

header .navigation > nav > ul > li > a:hover,

header .navigation > nav > ul > li > a.accented:hover {

    background-color: var( --details-hover-color );

}

header .navigation > nav > ul > li > a.active,

header .navigation > nav > ul > li > a.accented.active {

    color: var( --white-color );

    background-color: var( --accent-color );

}

header .navigation > nav > ul > li > a.accented {

    color: black;

    background-color: var( --accent-focus-color );

}

header .navigation > .clearance > a {

    display: flex;

    padding: 0.7em 1em;

    background-color: var( --accent-focus-color );

    color: var( --black-color );

    font-weight: 700;

    font-size: 0.9em;

    border-top-right-radius: 0.3em;

    border-top-left-radius: 0.3em;

    text-decoration: none;

    text-transform: uppercase;

    align-items: center;

    flex-direction: row;

    flex-wrap: nowrap;

}

header .navigation > .clearance > a > span {

    font-size: 20px;

    line-height: 0;

    margin-left: 5px;

}

header .navigation > .clearance > a.active,

header .navigation > .clearance > a:hover {

    background-color: var( --accent-color );

    color: var( --white-color );

}

header .swiper-navigation {

    display: none;

}

header .swiper-navigation a {

    display: flex;

    align-items: center;

    justify-content: center;

    background-color: var( --details-color );

    padding: 0.5em;

    color: var( --black-color );

    font-size: 0.9em;

    text-decoration: none;

    border-top-right-radius: 0.3em;

    border-top-left-radius: 0.3em;

    font-weight: 500;

    text-transform: uppercase;

}

header .swiper-navigation a.active,

header .swiper-navigation a.active.accented {

    background-color: var( --accent-color );

    color: var( --white-color );

}

header .swiper-navigation a.accented {

    background-color: var( --accent-focus-color );


}

.breadcrumbs,

.breadcrumbs-basic {

    position: relative;

    display: flex;

    align-items: center;

    flex-direction: row;

    flex-wrap: nowrap;

}

.breadcrumbs {
    
    justify-content: center;

}

.breadcrumbs-basic {

    justify-content: left;

    margin-top: 0.75em;

}

.breadcrumbs .list,

.breadcrumbs-basic .list {

    font-size: 0.8em;

    color: var( --secondary-text-color );

    left: 0;

}

.breadcrumbs .list { position: absolute; }

.breadcrumbs-basic .list { position: relative; }

.breadcrumbs > .list > .collection,

.breadcrumbs-basic > .list > .collection,

.breadcrumbs > .list > .collection > .item,

.breadcrumbs-basic > .list > .collection > .item,

.breadcrumbs > .list,

.breadcrumbs-basic > .list,

.breadcrumbs > .list > .item,

.breadcrumbs-basic > .list > .item {

    display: inline-flex;

    align-items: center;

    flex-direction: row;

    flex-wrap: nowrap;

}

.breadcrumbs > .list > .collection > .item > .icon,

.breadcrumbs-basic > .list > .collection > .item > .icon,

.breadcrumbs > .list > .item,

.breadcrumbs-basic > .list > .item {

    line-height: 0;

}

.breadcrumbs .collection > div:not( :last-child ), 

.breadcrumbs-basic .collection > div:not( :last-child ),

.breadcrumbs > .list > .collection > .item > div,

.breadcrumbs-basic > .list > .collection > .item > div {

    margin-right: 5px;

}

.breadcrumbs > .list > .item > .icon,

.breadcrumbs-basic > .list > .item > .icon {

    margin-right: 5px;

    margin-left: 5px;

}

.breadcrumbs > .list > .item > .name > a,

.breadcrumbs-basic > .list > .item > .name > a,

.breadcrumbs > .list > .collection > .item > .name > a,

.breadcrumbs-basic > .list > .collection > .item > .name > a {

    color: var( --secondary-text-color );

}

.breadcrumbs > .list > .collection > .item-end,

.breadcrumbs-basic > .list > .collection > .item-end {

    color: var( --black-color );

    font-weight: 500;

}

.breadcrumbs .quick-search > form {

    position: relative;

    display: inline-flex;

    align-items: center;

    flex-wrap: nowrap;

    flex-direction: row;

    background: var( --white-color );

    box-shadow: 0 2px 15px 10px rgb(0 0 0 / 10%);

    padding: 10px;

}

.breadcrumbs .quick-search > form::before, 

.breadcrumbs .quick-search > form::after {

    content: "";

    position: absolute;

    width: 20px;

    height: 100%;

    background-repeat: no-repeat;

}

.breadcrumbs .quick-search > form::before {

    left: -20px;

    background-image: url( "../Assets/search-left.png" );

}   

.breadcrumbs .quick-search > form::after {

    right: -20px;

    background-image: url( "../Assets/search-right.png" );

} 

.breadcrumbs .quick-search > form > div:not( :last-child ) {

    margin-right: 10px;

}

.breadcrumbs .quick-search > form > .submit {

    line-height: 0;

}

.breadcrumbs .quick-search > form > .icon,

.breadcrumbs .quick-search > form > .submit > button {

    line-height: 0;

    font-size: 20px;

}

.breadcrumbs .quick-search > form > .submit > button {

    background: none;

    border: none;

}

.breadcrumbs .quick-search > form > .icon {

    color: var( --details-color );

}   

.breadcrumbs .quick-search > form > .input > input {

    background: none;

    border: none;

    outline: none;

    width: 250px;

    max-width: 300px;

}   

header .actions a > .content-counter {

    position: absolute;

    top: -5px;

    right: -10px;

}

header .actions a > .content-counter > span {

    color: #FFF;

    background-color: var(--accent-color );

    font-size: 12px;

    font-weight: 500;

    padding: 1px 6px;

    border-radius: 4px;

}

header .mobile-navigation > .contacts > a {

    color: #FFF;

}

header .swiper-button-next,

header .swiper-button-prev {

    pointer-events: none;

}

header .swiper-button-next.swiper-button-disabled, 

header .swiper-button-prev.swiper-button-disabled {

    opacity: 0;

}

header .swiper-button-next:after, 

header .swiper-button-prev:after {

    font-family: swiper-icons;

    font-size: 0.8rem;

    background: var(--accent-color);

    color: white;

    border-radius: 0.3rem;

    padding: 5px 8px;

    font-weight: 600;

    text-transform: none!important;

    letter-spacing: 0;

    font-variant: initial;

    line-height: 1;

    opacity: 0.9;

}

header .swiper-button-next {

    right: 5px;

}

header .swiper-button-prev {

    left: 5px;

}