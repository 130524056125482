.notice {

    width: 100%;

    border: 1px solid;

    border-left-width: 8px;

    padding: 1em;

    border-radius: 0.3em;

    display: flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.notice > span.icon {

    line-height: 0;

    margin-right: 1em;

    font-size: 20px;

}

.notice.neutral > span.icon {

    color: var( --details-hover-color );

}

.notice.neutral {

    border-color: var( --details-hover-color );

}   