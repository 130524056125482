.policies > .layout {

    display: flex;

    flex-direction: row;

    flex-wrap: nowrap;

}

.policies > .title > h2 {

    text-transform: uppercase;

}

.policies > .layout > aside {

    margin-right: 3em;

    width: 100%;

    max-width: 270px;

    min-width: 250px;

}

.policies > .layout > section {

    flex: 1;

}   

.policies > .layout > aside > ul {

    position: sticky;

    top: 158px;

    border-radius: 0.3em;

}

.policies > .layout > aside > ul > li:not( :last-child ) {

    margin-bottom: 0.5em;

    padding-bottom: 0.5em;

    border-bottom: 1px dashed var( --details-color );

}

.policies > .layout > aside > ul > li > a {

    text-decoration: none;

    color: var( --black-color );

    font-size: 0.9em;

}

.policies > .layout > aside > ul > li > a.active {

    color: var( --secondary-text-color );

    pointer-events: none;

}