.services > .layout {

    display: flex;

    flex-direction: row;

    flex-wrap: nowrap;

}

.services > .title > h2 {

    text-transform: uppercase;

}

.services > .layout > aside {

    margin-right: 2em;

    width: 100%;

    max-width: 320px;

    min-width: 250px;

}

.services > .layout > section {

    flex: 1;

}   

.services > .layout > aside > div:not( :last-child ),

.services > .layout > section > div:not( :last-child ) {

    margin-bottom: 2em;

}

.services aside .widget > .navigation {

    border-radius: 0.3em;
    
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    
    padding: 2em;

}

.services aside .widget > .navigation > ul > li:not( :last-child ) {

    margin-bottom: 1em;

}

.services aside .widget > .navigation > ul > li > a {

    text-decoration: none;

    color: var( --black-color );

    font-weight: 600;

    outline: none;

}

.services aside .widget > .navigation > ul > li > a:hover,

.services aside .widget > .navigation > ul > li > a.active {

    color: var( --accent-color );

}

.services section > .list {

    display: grid;

    grid-template-columns: repeat( 2, 1fr );

    grid-gap: 2em;

}

.services section > .list > a {

    position: relative;

    width: 100%;

    border-radius: 0.3em;

    overflow: hidden;

    background-color: #000;

}

.services section > .list > a::after {

    content: "";

    padding-bottom: 50%;

    display: block;
        
}

.services section > .list > a > img {

    position: absolute;

    width: 100%;

    height: 100%;

    top: 0;

    left: 0;

    object-fit: cover;

}

.services section > .list > a > .title {

    position: absolute;

    bottom: 0;

    width: 90%;
    
    background-color: rgba( 201, 35, 35, 0.8 );

    left: 50%;

    transform: translateX( -50% );

    text-align: center;

    border-top-right-radius: 0.3em;

    border-top-left-radius: 0.3em;

    padding: 1em;

    color: var( --white-color );

    font-weight: 600;

}

.services section > .list > a:hover > .title {

    background-color: rgba( 201, 35, 35, 1 );

}

.services > .layout > section > .mobile-aside > .select,

.services > .layout > section > .mobile-aside > label > select {

    width: 100%;

}

.services .mobile-aside { display: none; }

.services > .layout > section > .paragraph > p {

    padding: 0.5em 0;

    line-height: 2;

}

.services > .layout > section > .paragraph hr {

    border-bottom: none;

    border-right: none;

    border-left: none;

    border-top: 1px solid var( --details-color );

    margin: 1em 0;

}

.services > .layout > section > .paragraph h1,

.services > .layout > section > .paragraph h2,

.services > .layout > section > .paragraph h3,

.services > .layout > section > .paragraph h4,

.services > .layout > section > .paragraph h5 {

    font-weight: 500;

}

.services > .layout > section > .paragraph strong {

    font-weight: 600;

}

.services > .layout > section > .paragraph a {

    text-decoration: none;

    color: var( --accent-color );

}

.services > .layout > section > .paragraph ul {

    line-height: 2;

    margin-left: 16px;

}

.services > .layout > section > .paragraph ul > li:not( :last-child ) {

    margin-bottom: 0.5em;

}

.services > .layout > section > .paragraph ul > li::before {

    content: "\2022";

    color: var( --accent-color );

    font-weight: bold;

    display: inline-block;

    width: 1em;

    margin-left: -1em;

}

.services > .layout > section > .paragraph table {

    border: 1px solid var( --details-color );

    max-width: 100%;

}   

.services > .layout > section > .paragraph table td {

    padding: 0.5em;

}

.services aside .widget > .banner {

    padding: 2em 1em;

    text-align: center;

    background-color: var( --details-color );

    border-radius: 0.3em;

    text-transform: uppercase;

    font-weight: 500;

    letter-spacing: 1px;

    color: var( --secondary-text-color );

}

.services .tech-list > *:not(:last-child) {

    margin-bottom: 2rem;

}

.services .tech-list > a {

    display: grid;

    grid-template-columns: 0.3fr 0.7fr;

    grid-gap: 2rem;

    width: 100%;

    text-decoration: none;

}

.services .tech-list > a > .image {

    position: relative;

}

.services .tech-list > a > .image::after {

    content: "";

    display: block;

    padding-bottom: 100%;

}

.services .tech-list > a > .image > img {

    position: absolute;

    width: 100%;

    height: 100%;

    top: 0;

    left: 0;

    object-fit: cover;

}

.services .tech-list > a > .tech-container > *:not( :last-child ) {

    margin-bottom: 1rem;

}

.services .tech-list > a > .tech-container > .title {

    color: var( --black-color );

    font-weight: 600;

}

.services .tech-list > a > .tech-container > .excerpt {

    color: var( --black-color );

    line-height: 2;

}