.contents > .layout {

    display: flex;

    flex-direction: row;

    flex-wrap: nowrap;

}

.contents > .title > h2 {

    text-transform: uppercase;

}

.contents > .layout > aside {

    margin-right: 3em;

    width: 100%;

    max-width: 270px;

    min-width: 250px;

}

.contents > .layout > section {

    flex: 1;

}   

.contents > .layout > aside > div:not( :last-child ) {

    margin-bottom: 1em;

}

.contents > .layout > aside > ul {

    position: sticky;

    top: 158px;

    border-radius: 0.3em;

}

.contents > .layout > aside > ul > li:not( :last-child ) {

    margin-bottom: 0.5em;

    padding-bottom: 0.5em;

    border-bottom: 1px dashed var( --details-color );

}

.contents > .layout > aside > ul > li > a {

    text-decoration: none;

    color: var( --black-color );

    font-size: 0.9em;

}

.contents > .layout > aside > ul > li > a.active {

    color: var( --secondary-text-color );

    pointer-events: none;

}

.contents > .layout > section > *,

.policies > .layout > section > * {

    line-height: 2;

}

.contents > .layout > section > *:not( :last-child ), 

.policies > .layout > section > *:not( :last-child ) {

    margin-bottom: 1em;

}

.policies > .layout > section > ul {

    line-height: 2;

    margin-left: 16px;

}

.policies > .layout > section > ul > li:not( :last-child ),

.contents > .layout > section > ul > li:not( :last-child ) {

    margin-bottom: 0.5em;

}

.policies > .layout > section > ul > li::before,

.contents > .layout > section > ul > li::before {

    content: "\2022";

    color: var(--accent-color );

    font-weight: bold;

    display: inline-block;

    width: 1em;

    margin-left: -1em;
    
}