@media only screen and ( max-width: 1024px ) {

    .product-catalog > .layout > section > .actions > .display {

        display: none;

    }

    .product-catalog > .layout > section > .actions > .sort {
        
        margin-right: 0!important;
        
    }

    .product-catalog > .layout > section > .products.grid {

        grid-template-columns: repeat( 2, 1fr );

    }

    .footer-layout,
    
    .contacts .team { grid-template-columns: repeat( 3, 1fr ); }

    .montage .montage-application .datetime .card > label {

        flex-direction: column;

    }

    .montage .montage-application .datetime .card > label > div:not( :last-child ) {

        margin-right: 0;

        margin-bottom: 8px;

    }

    .single-product > .layout > section > .product > .data > .delivery > .delivery_option {

        flex-direction: column;

    }

}

@media only screen and ( max-width: 768px ) {

    .checkout_sp .form-group {

        grid-template-columns: 1fr;

    }

    .checkout_sp .radios {

        display: inline-flex;

        flex-direction: column;

        flex-wrap: nowrap;

        align-items: center;

        width: 100%;

    }

    .checkout_sp .radios > div:not( :last-child ),
    
    .checkout_sp .submit > *:not( :last-child ) {

        margin-right: 0;

        margin-bottom: 1rem;

    }

    .checkout_sp .radios > .radio {

        width: inherit;

    }

    .checkout_sp .notification,
    
    .checkout_sp .agreement > p {

        text-align: center;

    }

    .checkout_sp .radios > .radio > label {

        width: 100%;

    }

    .checkout_sp .submit {

        flex-direction: column;

        width: 100%;

    }

    .checkout_sp .submit > span {

        text-align: center;

    }

    .checkout_sp .submit > span::before {

        content: '';

        display: inline-block;

        width: 0;

        height: 0;

        border-left: 5px solid transparent;

        border-right: 5px solid transparent;

        border-bottom: 5px solid var( --accent-focus-color );

        margin-right: 5px;

        top: -5px;

        left: 50%;

    }

    .checkout_sp h2 {

        text-align: center;

    } 

    .home .benefits,
    
    .home .categories {

        display: block;

    }

    .show-768 {

        display: block;

    }

    .home .categories > div > section {

        grid-template-columns: repeat( 2, 1fr );

    }

    .home .categories > div > h2 {

        margin-bottom: 1rem;

    }

    .product-catalog > .layout > section > .products.grid > .item > a > .thumbnail {

        margin: 1em 3em;

    }

    .home .blog > .content > .articles,
    
    .content.compare .table > .items {

        grid-template-columns: repeat(2, 1fr);

    }

    .montage .type, 
    
    .montage .montage-application, 
    
    .contacts .contacts-information {

        flex-direction: column;

    }   

    .montage .type > label:not( :last-child ) {

        margin-right: 0;

        margin-bottom: 1em;

    }

    .montage .montage-application > div:not( :last-child ) {

        margin-right: 0;

        margin-bottom: 2em;

        flex-direction: column;

    }

    .montage .calendar {

        display: inline-flex;

        justify-content: center;

    }

    .home .services > .content {

        grid-template-columns: repeat( 2, 1fr );

    }

    .services > .layout > aside {

        max-width: 256px;

    }

    .services section > .list,
    
    .product-catalog > .layout > section > .products.grid {

        grid-template-columns: 1fr;

    }

    .services .tech-list > a {

        grid-template-columns: 1fr;

        text-align: center;

    }

    .blog > .layout > aside { display: none; }

    .breadcrumbs,
    
    .policies > .layout,
    
    .contents > .layout { flex-direction: column; }

    .policies > .layout > aside,
    
    .contents > .layout > aside {

        margin-right: 0;

        margin-bottom: 3em;

        max-width: inherit;

        min-width: inherit;

    }

    .breadcrumbs .quick-search { margin-bottom: 1em; }

    .breadcrumbs .list { position: static; }

    header .navigation { display: none; }

    header .swiper-navigation { display: block; }

    .footer-layout,
    
    .contacts .team { grid-template-columns: repeat( 2, 1fr ); }

    .home .search > .content > .properties, 
    
    .content.cart > .layout > section > .items > .item > .data {

        flex-direction: column;

    }

    .home .search > .content > .properties > div:not( :last-child ),
    
    .content.cart > .layout > section > .items > .item > .data > div:not( :last-child ) {

        margin-right: 0;

        margin-bottom: 0.5em;

    }

    .home .search > .content > .properties > div,
    
    .home .search > .content > .properties > .field > .search-select > label, 
    
    .home .search > .content > .properties > .field > .search-select > label > select {

        width: 100%;

    }

    .footer-gateway > .icons {

        grid-gap: 2em;

    }

    .contacts .contacts-information > .right {

        margin-top: 1em;

    }

    .contacts .contacts-information > div:not( :last-child ) {

        margin-right: 0;

    }   

    .contacts .location::after {

        padding-bottom: 100%;

    }

    .content.cart > .layout > section > .items > .item > .data,
    
    .product-catalog > .layout > section > .products.list > .item > .name, 
    
    .quick-search > .results > .collection > .item > .name,
    
    .quick-search .item > div > .product-name {

        text-align: center;

    }

    .product-catalog > .layout > section > .products.list > .item, 
    
    .quick-search > .results > .collection > .item,
    
    .quick-search .item > div {

        flex-direction: column;

    }

    .product-catalog > .layout > section > .products.list > .item > div:not( :last-child ), 
    
    .quick-search > .results > .collection > .item > div:not( :last-child ),
    
    .product-catalog > .layout > section > .products.list > .item > a > .thumbnail, .quick-search > .results > .collection > .item > a > .thumbnail,
    
    .quick-search .item > div > div:not( :last-child ) {

        margin-right: 0;

        margin-bottom: 1rem;

    }

    header .language-selector > .selector > span {

        margin-left: 0;

        margin-right: 5px;

    }   

    .hide-768 {

        display: none;

    }

    .site-policy .policy {

        flex-direction: column;

        text-align: center;

    }

    .site-policy .policy > *:not( :last-child ) {

        margin-right: 0;

        margin-bottom: 1rem;

    }

}

@media only screen and ( max-width: 700px ) {

    .single-product > .layout > section > .product > .data > .delivery > .delivery_option > div {

        justify-content: center;

    }

    .home .benefits .list > .section > .title,
    
    .home .benefits .list > .section > .description {

        font-size: 0.8rem;

    }

    .home .benefits .list {

        grid-gap: 0.5rem;

    }

    .home .search .vehicle-type { display: none!important; }

    .swiper-vehicle-types,
    
    .widget > .sticky-close {

        display: block;
    
    }

    header .actions > ul > li:not( :last-child ) {

        margin-right: 0.8rem;

        padding-right: 0.8rem;

    }

    header .mobile-navigation-action {

        margin-left: 0.8rem;

        padding-left: 0.8rem;

    }

    .filter-tooltip {

        transform: translate( -100%, -100% );

    }

    .home .blog > .content > .articles, 
    
    .content.compare .table > .items,
    
    .checkout .sector .fields {

        grid-template-columns: 1fr;

    }

    .close-mobile-filters,
    
    .swiper-blog-posts {

        display: block;

    }

    .services > .layout > aside,
    
    .layout > section > .actions > .sort > span,
    
    .home .blog > .content > .articles {

        display: none;

    }

    .layout > .container > .single-product > .title {

        display: none;

    }

    .single-product > .layout > section > .product > .data > .add-to-cart > .quantity-content > .up, 
    
    .single-product > .layout > section > .product > .data > .add-to-cart > .quantity-content > .down,
    
    .single-product > .layout > section > .product > .data > .mobile-title,
    
    .services .mobile-aside {

        display: block;

    }

    .single-product > .layout > section > .product > .data > .add-to-cart > .quantity-content > .up {

        margin-left: 1em;
            
    }

    .single-product > .layout > section > .product > .data > .add-to-cart > .quantity-content > .down {

        margin-right: 1em;

    }

    .single-product > .layout > section > .product > .data > .add-to-cart > .label {

        display: none;

    }

    .single-product > .layout > section > .product > .data > .add-to-cart > div:not( :last-child ) {

        margin-right: 0;

        margin-bottom: 1em;

    }

    .single-product > .layout > section > .product > .data > .add-to-cart > .add,
    
    .single-product > .layout > section > .product > .data > .add-to-cart > .add > button {

        width: 100%;

    }

    .single-product > .layout > section > .product > .data > .add-to-cart, 
    
    .product-catalog > .layout,
    
    .home .search .vehicle-type,
    
    .checkout .sector > .radios {

        flex-direction: column;

    }

    .checkout .sector > .radios {

        align-items: flex-start;

    }

    .checkout .sector > .radios > div:not( :last-child ) {

        margin-right: 0;

        margin-bottom: 1rem;

    }

    .single-product > .layout > section > .product > .data > .stock,
    
    .single-product > .layout > section > .product > .data > .features, 
    
    .single-product > .layout > section > .product > .data > .properties {

        justify-content: center;
        
    }
    
    .single-product > .layout > section > .product > .data > .add-to-compare > button {

        width: 100%;

    }

    .blog > .layout > section .collection > .articles, 
    
    .home .services > .content {

        grid-template-columns: 1fr;

    }

    .breadcrumbs .quick-search > form > .input > input { width: 210px; }

    .temp-notice { flex-direction: column; }

    .temp-notice > .message {

        line-height: 2;

        text-align: center;

    }

    .temp-notice > div:not( :last-child ),
    
    .home .search .vehicle-type > div:not( :last-child ) {

        margin-right: 0;

        margin-bottom: 1rem;
    
    }

    header .mobile-navigation-action { display: block; }

    header .type-desktop { display: none; }

    .max-content-width {

        padding-right: 1em;

        padding-left: 1em;

    }

    footer .footer-information ul > li { justify-content: center; }

    .footer-layout, 
    
    .contacts .team,
    
    .single-product > .layout > section > .product { 

        text-align: center;
        
        grid-template-columns: repeat( 1, 1fr ); 
    
    }

    .montage .appointment-table > .appointment-row {

        width: 100%;

        display: inline-flex;

        flex-direction: column;

        flex-wrap: nowrap;

        align-items: center;

    }

    .montage .appointment-table > .appointment-row > div:not( :last-child ) {

        margin-right: 0;

        border: none;

    }

    .montage .appointment-table > .appointment-row .appointment-data-text,
    
    .montage .appointment-table > .appointment-row .appointment-data-value {

        text-align: center;

    }

    .montage .successful-appointment-actions {

        display: inline-flex;

        flex-direction: column;

        flex-wrap: nowrap;

        align-items: center;

        width: 100%;

    }

    .montage .successful-appointment-actions > *:not( :last-child ) {

        margin-right: 0;

        text-align: center;

        margin-bottom: 1em;

    }

    .liaa {

        flex-direction: column;

    }

    .liaa > div:not( :last-child ) {

        margin-right: 0;

        margin-bottom: 1em;

    }

    .liaa > .paragraph {

        text-align: center;

    }

    .mobile-filters-switch {

        flex: 1;

        display: inline-flex;

        flex-direction: row;

        flex-wrap: nowrap;

        align-items: center;

    }

    .product-catalog > .layout > aside > .widget {

        position: fixed;

        border-radius: 0.3em;

        box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

        padding: 1em;

        top: 0;

        left: 0;

        width: 70%;

        height: 100%;

        background: white;

        z-index: 998;

        overflow-y: scroll;

        display: none;

    }

    .product-catalog .aside-banner {

        display: none;

    }

    .home .categories > div > section > .category > .title {

        font-size: 1rem;

    }

    footer .copyrights {

        align-items: center;

    }

}

@media only screen and ( max-width: 425px ) {

    .checkout_sp {

        padding: 1rem;

    }

}

@media only screen and ( max-width: 375px ) {

    .home .categories > div > section > .category > .title {

        font-size: 0.9rem;
        
    }

    .breadcrumbs .quick-search > form > .input > input { width: 150px; }

    footer .mobile-copyrights { display: block; }

    footer .copyrights > .logotype > img { max-width: 128px; }

    footer .copyrights { flex-direction: column; }

    .subscribe > form > .confirmation > label { 
        
        font-size: 0.9em; 
        
        justify-content: center; 
    
    }

    footer .copyrights > .content { display: none; }

    .footer-gateway > .icons {

        grid-gap: 1em;

    }

}

@media only screen and ( max-width: 320px ) {

    header .language-selector > .selector > span {

        margin-right: 5px;

        margin-left: 5px;

    }

    header .actions > ul > li:not( :last-child ) {

        margin-right: 0.5em;

        margin-left: 0.5em;

    }

    .header .mobile-navigation-action {

        margin-left: 0.5em;

        padding-left: 0.5em;

    }

    .subscribe > form > .title { font-size: 0.9em; }

}

.widget.mobile-filters-state {

    animation-name: openFilters;

    animation-duration: 0.3s;

    animation-timing-function: ease;

    animation-fill-mode: forwards;

}

.mobile-shadow {

    position: fixed;

    width: 30%;

    height: 100vh;

    background-color: black;

    right: 0;

    top: 0;

    z-index: 990;

    opacity: 0;

    animation-name: openFiltersShadows;

    animation-delay: 0.3s;

    animation-duration: 0.3s;

    animation-timing-function: ease;

    animation-fill-mode: forwards;

}

@keyframes openFilters {
    
    0% { left: -70%; opacity: 0; }

    100% { left: 0; opacity: 1; }

}

@keyframes openFiltersShadows {
    
    0% { opacity: 0; }

    100% { opacity: 0.3; }

}