.aside-banner {

    text-align: center;

}

.aside-banner > a > img {

    max-width: 100%;

}