footer {

    margin-top: 50px;

    position: relative;

}

footer > .footer {

    position: relative;

    background-color: var( --footer-color );

    padding-top: 5em;

    padding-bottom: 5em;

}

.footer-layout {

    display: grid;

    grid-gap: 2em;

    grid-template-columns: 1fr 0.9fr 0.5fr 0.5fr 0.5fr;

}

.footer-layout > .pane > div:not( :last-child ) {

    margin-bottom: 2em;

}

.footer-navigation > nav > ul > li:not( :last-child ) {

    margin-bottom: 0.5em;

}

.footer-navigation > nav > ul > li > a {

    text-decoration: none;

    color: var( --secondary-text-color );

    font-size: 0.9em;

}

.footer-navigation > nav > ul > li > a.active {

    color: var( --white-color );

}

.footer-navigation > nav > ul > li > a:hover, 

.footer-navigation > ul > li > a:focus {

    outline: none;

    color: var( --white-color );

}

footer .copyrights {

    display: flex;

    flex-direction: column;

    flex-wrap: nowrap;

    align-items: flex-start;

}

footer .copyrights > div:not( :last-child ) {

    margin-bottom: 1rem;

}

footer .copyrights > .logotype > img {

    width: 100%;

    max-width: 200px;

}

footer .copyrights > div {

    flex: 1;

}

footer .copyrights > .content {

    text-align: right;

    font-size: 0.9em;

    color: var( --white-color );

}

footer .subscribe form > div:not( :last-child ) {

    margin-bottom: 1em;

}

.subscribe > form > .title {

    color: var( --white-color );

    font-weight: 600;

}

.subscribe > form > .confirmation {

    color: var( --white-color );

    font-size: 0.9em;

}

.subscribe > form > .confirmation > label {

    display: flex;

    align-items: center;

    flex-direction: row;

    flex-wrap: nowrap;

}

.subscribe > form > .confirmation > label > input[type="checkbox"] {

    margin-right: 0.5em;

}

.subscribe > form > .confirmation > label > a {

    color: var( --accent-color );

    margin-left: 0.3em;

}

.subscribe > form > .fields > input {

    width: 100%;

    margin-right: 10px;

    background: none;

    border: none;

    color: var( --white-color );

    outline: none;

}

.subscribe > form > .fields {

    display: flex;

    align-items: center;

    flex-direction: row;

    flex-wrap: nowrap;

    border: 2px solid var( --white-color );

    padding: 10px;

}

.subscribe > form > .fields > button {

    background: none;

    border: none;

    color: var( --accent-color );

    line-height: 0;

    font-size: 20px;

}

footer .footer-information ul > li:not( :last-child ) {

    margin-bottom: 0.5em;

}

footer .footer-information ul > li {

    display: flex;

    font-size: 0.9em;

    align-items: center;

    flex-direction: row;

    flex-wrap: nowrap;

}

footer .footer-information ul > li > .name {

    color: var( --white-color );

    font-weight: 600;

    margin-right: 6px;

}

footer .footer-information ul > li > .style-default {

    color: var( --secondary-text-color );

}

footer .footer-information ul > li > .style-accented {

    color: var( --accent-color );

}

footer .footer-information ul > li > .content > a {

    color: var( --accent-color );

}

footer .return-top {

    position: absolute;

    top: -20px;

    left: 50%;

    transform: translateX( -50% );

    background: var( --accent-color );

    outline: none;

    border: 2px solid var( --footer-color );

    line-height: 0;

    padding: 10px;

    border-radius: 50%;

    color: var( --white-color );

    font-size: 20px;

}

footer .return-top:hover {

    color: var( --footer-color );

    background-color: var( --accent-focus-color );

    cursor: pointer;

}

footer .mobile-copyrights {

    display: none;

    color: var( --white-color );

    text-align: center;

    margin-top: 2em;

    font-size: 0.9em;

}

.footer-salidzini > a > img {

    width: 100%;

    max-width: 128px;

}

.footer-gateway > div:not( :last-child ) {

    margin-bottom: 1em;

}

.footer-gateway > .description {

    color: var( --secondary-text-color );

    font-size: 0.9em;

    line-height: 2;

}

.footer-gateway > .icons {

    display: grid;

    grid-template-columns: repeat(4,1fr);

    align-items: center;

    grid-gap: 1em;

    width: 100%;

}

.footer-gateway > .icons > img {

    flex: 1;

    width: 100%;

    max-width: 200px;

    object-fit: contain;

}

footer .social-networks {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

footer .social-networks > a:not( :last-child ) {

    margin-right: 0.5rem;

}

footer .social-networks > a {

    font-size: 2rem;

    color: var( --secondary-text-color );

    outline: 0;

}

footer .social-networks > a:hover {

    color: var( --accent-color );

}