.contacts > section:not( :last-child ) {

    margin-bottom: 2em;

}

.contacts > .section > h2 {

    margin-bottom: 1em;

    text-transform: uppercase;

    letter-spacing: 1px;

}

.contacts .team {

    display: grid;

    flex-wrap: wrap;

    justify-content: center;

    grid-template-columns: repeat( 4, 1fr );

    grid-gap: 1.5em;

}

.contacts .team > .member {

    padding: 2em 1em;

    border-radius: 0.3em;

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

}

.contacts .team > .member > div:not( :last-child ) {

    margin-bottom: 1em;

}

.contacts .team > .member > div {

    text-align: center;

}

.contacts .team > .member > .name {

    font-weight: 500;

}

.contacts .team > .member > .contacts .email {

    color: var( --accent-color );

    text-transform: uppercase;

    font-weight: 500;

    font-size: 0.8em;

    letter-spacing: 1px;

}

.contacts .team > .member > .position {

    font-size: 0.9em;

}

.contacts .team > .member > .contacts .email > a {

    color: var( --accent-color );

    text-decoration: none;

}

.contacts .team > .member > .contacts .phone > a[href^="tel"] {

    font-size: 0.9em;

    text-decoration: none;

    color: var( --black-color );

}

.contacts .team > .member > .contacts > ul > li:not( :last-child ) {

    margin-bottom: 0.5em;

}

.contacts .team > .member > .thumbnail > .image {

    position: relative;

    margin: auto;

    background-color: var( --details-color );

    width: 70%;

    border-radius: 50%;

    overflow: hidden;

}

.contacts .team > .member > .thumbnail > .image::after {

    content: "";

    display: block;

    padding-bottom: 100%;

}

.contacts .team > .member > .thumbnail > .image > img {

    position: absolute;

    width: 100%;

    height: 100%;

    top: 50%;

    left: 50%;

    transform: translate( -50%, -50% );

    object-fit: cover;

}

.contacts .contacts-information {

    display: flex;

    flex-direction: row;

    flex-wrap: nowrap;

}

.contacts .contacts-information > .left {

    flex: 0.5;

}   

.contacts .contacts-information > .right {

    flex: 1;

}   

.contacts .contacts-information > .right > div:not( :last-child ),

.contacts .contacts-information > .left > div:not( :last-child ){

    margin-bottom: 1em;
    

}

.contacts .contacts-information > div:not( :last-child ) {

    margin-right: 1em;

}

.contacts .contacts-information > .left > div,

.contacts .contacts-information > .right > div:not( .location ) {

    border-radius: 0.3em;

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

    padding: 1.5em;

}

.contacts .requisites > div:not( :last-child ) {

    margin-bottom: 1em;

}

.contacts .requisites > .headline,

.contacts .office-info > div > .headline {

    font-weight: 500;

}

.contacts .requisites > .list > ul > li:not( :last-child ),

.contacts .office-info > .communication > .content > ul > li:not( :last-child ),

.contacts .office-info > .hours > .content > ul > li:not( :last-child ) {

    margin-bottom: 0.5em;

}

.contacts .requisites > .list > ul > li,

.contacts .office-info > .communication > .content > ul > li,

.contacts .office-info > .hours > .content > ul > li {

    font-size: 0.9em;

}   

.contacts .office-info > .communication > .content > ul > li > a {

    margin-left: 5px;

    color: var( --black-color );

}

.contacts .office-info > div:not( :last-child ) {

    margin-bottom: 1.5em;

}

.contacts .office-info > div > div:not( :last-child ) {

    margin-bottom: 1em;

}

.contacts .office-info > .communication > .content > ul > li {

    display: flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.contacts .office-info > .communication > .content > ul > li > span {

    line-height: 0;

    margin-right: 0.5em;

}

.contacts .location {

    position: relative;

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

}

.contacts .location::after {

    content: "";

    display: block;

    padding-bottom: 50%;
    
}

.contacts .location > iframe {

    position: absolute;

    display: block;

    width: 100%;

    height: 100%;

    border: none;

    border-radius: 0.3em;

}

.contacts .social-networks {

    display: inline-flex;

    width: 100%;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.contacts .social-networks > a {

    line-height: 0;

    font-size: 32px;

    color: var( --black-color );

    outline: none;

}

.contacts .social-networks > a:hover {

    color: var( --accent-color );

}

.contacts .social-networks > a:not( :last-child ) {

    margin-right: 16px;

}

.contacts .contacts-form > form > *:not( :last-child ),

.contacts .contacts-form > form > .checkbox-group > *:not( :last-child ) {

    margin-bottom: 1rem;

}

.contacts .contacts-form > form > .title {

    font-weight: 500;

}

.contacts .contacts-form > form > input[type="submit"] {

    background-color: var(--accent-color );

    color: #FFF;

    border: none;

    outline: none;

    display: inline-block;

    padding: 1em;

    border-radius: 0.3em;

    font-weight: 600;

    text-transform: uppercase;

    outline: none;

}

.contacts .contacts-form > form > input[type="submit"]:hover {

    cursor: pointer;

    background-color: var( --accent-focus-color );

    color: var( --black-color );

}

.contacts .contacts-form > form > .field-group {

    display: grid;

    grid-template-columns: repeat( 3, 1fr );

    grid-gap: 1rem;

}

.contacts .contacts-form > form > .field-group > .field > input,

.contacts .contacts-form > form > .textarea > .field > textarea {

    display: block;

    width: 100%;

    border: 2px solid var( --details-color );

    border-radius: 0.3rem;

    outline: none;

    padding: 0.5rem;

}

.contacts .contacts-form > form > .field-group > .field > input.error,

.contacts .contacts-form > form > .textarea > .field > textarea.error {

    border-color: var( --accent-color );

}

.contacts .contacts-form > form > .textarea > .field > textarea {

    resize: vertical;

    height: 128px;

    max-height: 256px;

}

.contacts .contacts-form > form > .field-group > .field > label,

.contacts .contacts-form > form > .textarea > .field > label {

    position: relative;

    display: inline-block;

    font-size: 0.9rem;

    margin-bottom: 0.5rem;

}

.contacts .contacts-form > form > .field-group > .field > label > span.required,

.contacts .contacts-form > form > .textarea > .field > label > span.required {

    color: var( --accent-color );

}

.contacts .contacts-form > form > .field-group > .field > label.required::after,

.contacts .contacts-form > form > .textarea > .field > label.required::after {

    content: "";

    position: absolute;

    top: 0;

    right: -10px;

    width: 5px;

    height: 5px;

    background-color: var( --accent-color );

    display: block;

    border-radius: 50%;
    
}

.contacts .contacts-form > form > .checkbox-group > label > span.name > a {

    color: var( --accent-color );

    outline: none;

}

.contacts .contacts-form > form > input[type="submit"]:disabled {

    opacity: 0.5;

    pointer-events: none;

}

.contacts .contacts-form > form > .send-successful {

    border-radius: 0.3rem;
    
    border: 1px solid #C8E6C9;
    
    color: #1B5E20;
    
    background-color: #E8F5E9;
    
    padding: 1rem;
    
    font-weight: 500;
    
    font-size: 0.9rem;

}

.contacts .contacts-form > form > .send-failed {

    border-radius: 0.3rem;

    border: 1px dashed #B71C1C;
    
    color: #B71C1C;

    padding: 0.5rem;

    font-weight: 500;

    font-size: 0.9rem;

}