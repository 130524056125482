.montage .layout > section > div:not( :last-child ) {

    margin-bottom: 2em;

}

.montage .type {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

    justify-content: space-between;

    width: 100%;

    color: #FFF;

}

.montage .type > label {

    width: 100%;

    background-color: var( --footer-color );

    border-radius: 0.3em;

    padding: 1em;

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.montage .type > label:not( :last-child ) {

    margin-right: 1em;

}

.montage .type > label > input {

    display: none;

}

.custom-radio {

    display: block;

    width: 15px;

    height: 15px;

    background-color: #FFF;

    border-radius: 50%;

    margin-right: 8px;

}

.montage .type > label:hover {

    cursor: pointer;

}

.montage .type > label > input[type="radio"]:checked ~ span.custom-radio  {

   background: var( --accent-focus-color );

}

.montage .type > label > input[type="radio"]:checked ~ span.custom-radio {

    animation-name: radioPulse;

    animation-duration: 0.5s;

    animation-timing-function: ease-out;

}

@keyframes radioPulse {

    0% { box-shadow: 0 0px 0px 0px rgb(255 204 0 / 1); }

    100% { box-shadow: 0 0px 0px 10px rgb(255 204 0 / 0); }
    
}

.montage .montage-application {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    width: 100%;

}

.montage .montage-application > .calendar > div:not( :last-child ) {

    margin-bottom: 1rem;

}

.montage .montage-application > .calendar > .notification {

    line-height: 2;

    font-size: 0.9rem;

    font-weight: 500;

    text-align: center;

}

.montage .montage-application > .calendar > .notification > a {

    color: var( --accent-color );

    display: inline-block;

}

.montage .montage-application > div {

    flex: 1;

}

.montage .montage-application > div:not( :last-child ) {

    margin-right: 2em;

}

.montage .montage-application .datetime > div:not( :last-child ) {

    margin-bottom: 1em;

}

.montage .montage-application .datetime .card > label {

    border-left: 10px solid;

    border-radius: 0.3em;

    padding: 8px;

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

    width: 100%;

}


.montage .montage-application .datetime .card > input {

    display: none;

}

.montage .montage-application .datetime .card > label.active {

    border-color: green;

}

.montage .montage-application .datetime .card > label.inactive {

    border-color: var( --accent-color );

}

.montage .montage-application .datetime .card > input[type="radio"]:checked ~ label {

    background-color: var( --footer-color );

    color: white;

}

.montage .montage-application .datetime .card > label:hover {

    cursor: pointer;

    background-color: #eee;

}   

.montage .montage-application .datetime .card > input[type="radio"]:disabled ~ label {

    opacity: 0.5;

}

.montage .montage-application .datetime .card > label,

.montage .montage-application .datetime .card > label > .box {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.montage .montage-application .datetime .card > label > .box {

    font-size: 0.9em;

}

.montage .montage-application .datetime .card > label > div:not( :last-child ) {

    margin-right: 0.5em;

}

.montage .montage-application .datetime .card > label > .time {

    font-weight: 500;

    flex: 1;

}

.montage .montage-application .datetime .card > label > .box > span {

    display: inline-block;

    width: 16px;

    height: 16px;

    border-radius: 50%;

    margin-right: 0.5em;

}

.montage .montage-application .datetime .card > label > .box > span.available {

    background-color: green;

}

.montage .montage-application .datetime .card > label > .box > span.unavailable {

    background-color: orange;

}

.montage .montage-application .montage-form > div:not( :last-child ) {

    margin-bottom: 1em;

}

.montage .montage-application .montage-form > .placeholder {

    background-color: var( --accent-color );

    color: #FFF;

    border-radius: 0.3em;

    padding: 0.5em;
    
    text-align: center;

    font-size: 0.9em;

    font-weight: 500;

}

.montage .montage-application .montage-form .form-fields > label,

.montage .montage-application .montage-form .form-fields > input {

    display: block;

}

.montage .montage-application .montage-form .form-fields > input,

.montage .montage-application .montage-form .form-fields > textarea {

    outline: none;

    width: 100%;

    border: 2px solid var( --details-color );

    padding: 0.5em;

    border-radius: 0.3em;

}

.montage .montage-application .montage-form .form-fields > textarea {

    resize: vertical;

    max-height: 100px;

    min-height: 45px;

}

.montage .montage-application .montage-form > .information {

    font-size: 0.9em;

}

.montage .montage-application .montage-form > .information > a {

    text-decoration: none;

    outline: none;

    color: var( --accent-color );

}

.montage .montage-application .montage-form > .information > a:hover {

    color: #000;

}

.montage .montage-application .montage-form .form-fields > label {

    margin-bottom: 0.5em;

    font-size: 0.9em;

}

.montage .montage-application .montage-form > .title {

    font-weight: 500;

    text-transform: uppercase;

    border-bottom: 1px solid var( --details-color );

    border-top: 1px solid var( --details-color );

    padding: 0.5em 0;

}

.montage input[type="checkbox"] {

    display: none;

}

.montage .montage-application .montage-form .form-checkbox > label {

    display: flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

    font-size: 0.9em;

}

.montage .montage-application .montage-form .form-checkbox > input[type="checkbox"]:checked ~ label > .custom-checkbox > span.checked {

    visibility: visible;

}

.montage .montage-application .montage-form .form-checkbox > input[type="checkbox"]:checked ~ label > .custom-checkbox {

    border-color: var( --footer-color );

}

.montage .montage-application .montage-form .form-checkbox > label > span.custom-checkbox {

    position: relative;

    display: flex;

    justify-content: center;

    align-items: center;

    width: 20px;

    height: 20px;

    border-radius: 0.3em;

    border: 2px solid var( --details-color );

    margin-right: 8px;

}

.montage .montage-application .montage-form .form-checkbox > label > span.custom-checkbox > span.checked {

    visibility: hidden;

    line-height: 0;

    font-size: 12px;

}

.montage .montage-application .montage-form > .submit > button {

    background-color: var( --accent-color );

    color: #FFF;

    border: none;

    outline: none;

    display: inline-block;

    padding: 1em;

    border-radius: 0.3em;

    text-transform: uppercase;

    font-weight: 600;

    font-size: 1.1em;

}

.montage .montage-application .montage-form > .submit > button:hover {

    cursor: pointer;

    background-color: var( --accent-focus-color );

    color: #000;

}

.montage .montage-application .montage-form > .submit > button:disabled {

    pointer-events: none;

    opacity: 0.5;

}

.montage .montage-errors {

    border: 1px solid #ffcdd2;

    border-radius: 0.3em;

    padding: 1em;

    background: #ffebee;

}

.montage .montage-errors > div:not( :last-child ) {

    margin-bottom: 0.2em;
    
    padding-bottom: 0.2em;
    
    border-bottom: 1px dashed #ffcdd2;

}

.montage .montage-errors > div {

    font-size: 0.8em;

    font-weight: 500;

    text-transform: uppercase;

    letter-spacing: 1px;

    color: #b71c1c;

}

.montage .appointment-table {

    border-radius: 0.3em;

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

}

.montage .appointment-table > div:not( :last-child ) {

    border-bottom: 1px dashed var( --details-color );

}

.montage .appointment-table > .appointment-row {

    width: 100%;

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.montage .appointment-table > .appointment-row > div {

    flex: 1;

    width: 100%;

    padding: 0.5em 1em;

}

.montage .appointment-table > .appointment-row > div:not( :last-child ) {

    margin-right: 1em;

    border-right: 1px dashed var( --details-color );

}

.montage .appointment-table > .appointment-row .appointment-data-value {

    text-align: right;

    text-transform: uppercase;

    font-weight: 500;

    letter-spacing: 1px;

}

.montage .appointment-table > .appointment-row .appointment-data-text {

    font-size: 0.9em;

    font-weight: 500;

    color: var(--secondary-text-color );

}

.montage .title > h2 {

    text-transform: uppercase;

}

.montage .remove-appointment > *:not( :last-child ) {

    margin-bottom: 2em;

}

.montage .remove-appointment > button {

    background-color: var(--accent-color );

    color: #FFF;

    border: none;

    outline: none;

    display: inline-block;

    padding: 1em;

    border-radius: 0.3em;

    text-transform: uppercase;

    font-weight: 600;

    font-size: 1.1em;

}

.montage .remove-appointment > button:hover {

    cursor: pointer;

    background: var( --accent-focus-color );

    color: var( --black-color );

}

.montage .remove-appointment > button:disabled {

    pointer-events: none;

    opacity: 0.5;

}

.montage .invalid-appointment {

    background-color: #ffebee;

    border: 1px solid #ffcdd2;

    border-radius: 0.3em;

    padding: 1em;

    color: #b71c1c;

    font-weight: 500;

}

.montage .appointment-eliminated > *:not( :last-child ) {

    margin-bottom: 1em;

}

.montage .appointment-eliminated > .message {

    background-color: #e8f5e9;

    border: 1px solid #c8e6c9;

    border-radius: 0.3em;

    padding: 1em;

    color: #1b5e20;

}

.montage .appointment-eliminated > .actions > a {

    color: var( --black-color );

    outline: none;

    display: inline-flex;

    flex-direction: row;

    align-items: center;

    flex-wrap: nowrap;
    
    text-decoration: none;

}

.montage .appointment-eliminated > .actions > a > span {

    margin-left: 0.5em;

}

.montage .appointment-eliminated > .actions > a:hover {

    color: var( --accent-color );

}

.montage .successful-appointment-actions {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.montage .successful-appointment-actions > *:not( :last-child ) {

    margin-right: 1em;

}

.montage .successful-appointment-actions > a.button {

    background-color: var(--accent-color );

    color: #FFF;

    border: none;

    outline: none;

    display: inline-block;

    padding: 1em;

    border-radius: 0.3em;

    text-transform: uppercase;

    text-decoration: none;

    font-weight: 600;

    font-size: 1.1em;

}

.montage .successful-appointment-actions > a.link {

    color: var( --black-color );

    font-size: 0.9em;

}

.montage .successful-appointment-actions > a.link:hover {

    color: var( --accent-color );

}

.montage .successful-appointment-actions > a.button:hover {

    cursor: pointer;

    background-color: var(--accent-focus-color );

    color: #000;

}

.montage_countdown {

    padding: 1rem;

    background: #d2d2d2;

    color: black;

    border-radius: 0.3rem;

}

.montage_countdown > span {

    font-weight: 600;

}