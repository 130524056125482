.home > div:not( :last-child ) {

    margin-bottom: 2em;

}

.home .home-heading > h2 {

    margin-bottom: 0.5em;

    text-transform: uppercase;

}

.home .home-heading {

    text-align: center;

}

.home .home-heading > p > a {

    color: var( --accent-color );

    text-decoration: none;

}

.home .home-heading > p > a:hover,

.home .home-heading > p > a:focus {

    color: var( --black-color );

}   

.home .services > div:not( :last-child ),

.home .sale > div:not( :last-child ),

.home .blog > div:not( :last-child ) {

    margin-bottom: 2em;

}

.home .search {

    position: relative;

    background-color: var( --black-color );

    color: var( --white-color );

    padding-top: 3em;

    padding-bottom: 3em;

    overflow: hidden;

}

.home .search > img {

    position: absolute;

    width: 100%;

    height: 100%;

    top: 0;

    left: 0;

    z-index: 0;

    object-fit: cover;

}

.home .search > .content {

    position: relative;

    z-index: 1;

}   

.home .search > .content > div:not( :last-child ) {

    margin-bottom: 2em;

}

.home .search > .content > .action {

    text-align: center;

}

.home .search > .content > .action > button {

    outline: none;

    background: var( --accent-color );

    border: none;

    border-radius: 0.3em;

    color: var( --white-color );

    padding: 16px;

    font-size: 16px;

    font-weight: 600;

    text-transform: uppercase;

    letter-spacing: 1px;

}

.home .search > .content > .action > button:hover {

    cursor: pointer;

    background: var( --accent-focus-color );

    color: var( --black-color );

}

.home .search > .content > .properties {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    justify-content: center;

    width: 100%;

    align-items: center;

}

.home .search > .content > .properties > div:not( :last-child ) {

    margin-right: 0.5em;

}

.home .search > .content > .properties > .field > div:not( :last-child ) {

    margin-bottom: 8px;

}

.home .search > .content > .properties > .field > .name {

    font-size: 0.9em;

}

.home .search > .content > .properties > .field > .search-select > label > select {

    border: none;

    appearance: none;

    font-size: 16px;

}

.home .search > .content > .properties > .field > .search-select > label {

    position: relative;

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

    border-radius: 0.3em;

}

.home .search > .content > .properties > .field > .search-select > label > span {

    position: absolute;

    line-height: 0;

    right: 10px;

    pointer-events: none;

    color: var( --black-color );

}

.home .search > .content > .properties > .field > .search-select > label > select {

    background: none;

    outline: none;

    padding-top: 10px;

    padding-bottom: 10px;

    padding-left: 10px;

    padding-right: 35px;

    background-color: white;

    border-radius: 0.3em;

}

.home .services > .content {

    display: grid;

    grid-template-columns: repeat( 3, 1fr );

    grid-gap: 1em;

}

.home .services > .content > a {

    position: relative;

    width: 100%;

    border-radius: 0.3em;

    overflow: hidden;

    background-color: #000;

}

.home .services > .content > a::after {

    content: "";

    padding-bottom: 50%;

    display: block;
        
}

.home .services > .content > a > img {

    position: absolute;

    width: 100%;

    height: 100%;

    top: 0;

    left: 0;

    object-fit: cover;

}

.home .services > .content > a > .title {

    position: absolute;

    bottom: 0;

    width: 90%;
    
    background-color: rgba( 201, 35, 35, 0.8 );

    left: 50%;

    transform: translateX( -50% );

    text-align: center;

    border-top-right-radius: 0.3em;

    border-top-left-radius: 0.3em;

    padding: 1em;

    color: var( --white-color );

    font-weight: 600;

}

.home .services > .content > a:hover > .title {

    background-color: rgba( 201, 35, 35, 1 );

}

.home .services > .view-more {

    text-align: center;

}

.home .services > .view-more > a {

    border: 2px solid var( --black-color );

    padding: 0.5em 1em;

    display: inline-block;

    text-decoration: none;

    color: var( --black-color );

    border-radius: 0.3em;

}

.liaa {

    border-top: 1px dashed var( --details-color );

    padding-top: 2em;

    width: 100%;

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

}

.liaa > div:not( :last-child ) {

    margin-right: 3em;

}

.liaa > .image > img {

    max-width: 320px;

}

.liaa > .paragraph {

    font-size: 13px;

    line-height: 2;

}

.home .blog > .content > .articles {

    display: grid;
    
    grid-template-columns: repeat( 4, 1fr );
    
    grid-gap: 1rem;

}

.swiper-blog-posts .swiper-button-next, .swiper-blog-posts .swiper-button-prev {

    width: 32px;

    height: 32px;

    background: var( --accent-color );

    color: var( --white-color );

    border-radius: 0.3rem;

}

.swiper-blog-posts .swiper-button-next:after, .swiper-blog-posts .swiper-button-prev:after {

    font-size: inherit;

}

.swiper-blog-posts .swiper-button-next {

    right: 0;

}

.swiper-blog-posts .swiper-button-prev {

    left: 0;

}

.swiper-blog-posts {

    display: none;

    padding-right: 1rem!important;

    padding-left: 1rem!important;

    padding-bottom: 0.8rem!important;

}

.home .blog > .content > .articles > article,

.swiper-blog-posts article {

    background-color: var(--white-color );

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

    border-radius: 0.3em;

    overflow: hidden;

}

.home .blog > .content > .articles > article > div,

.swiper-blog-posts article > div {

    margin-bottom: 1em;

}

.home .blog > .content > .articles > article > .thumbnail > a,

.swiper-blog-posts article > .thumbnail > a {

    position: relative;

    width: 100%;

    background-color: var(--details-color );

    display: block;

}

.home .blog > .content > .articles > article > .thumbnail > a > img,

.swiper-blog-posts article > .thumbnail > a > img {

    position: absolute;

    width: 100%;

    height: 100%;

    top: 50%;

    left: 50%;

    transform: translate( -50%, -50% );

    object-fit: cover;

}

.home .blog > .content > .articles > article > .thumbnail > a::after,

.swiper-blog-posts article > .thumbnail > a::after {

    content: "";

    display: block;

    padding-bottom: 50%;

}

.home .blog > .content > .articles > article > div:not( :first-child ),

.swiper-blog-posts article > div:not( :first-child ) {

    margin-right: 1em;

    margin-left: 1em;

}

.home .blog > .content > .articles > article > .title, 

.home .blog > .content > .articles > article > .description,

.swiper-blog-posts article > .title,

.swiper-blog-posts article > .description {

    text-align: center;

}

.home .blog > .content > .articles > article > div,

.swiper-blog-posts article > div {

    margin-bottom: 1em;

}

.home .blog > .content > .articles > article > .title > a,

.swiper-blog-posts article > .title > a {
    
    text-decoration: none;
    
    font-weight: 500;
    
    color: var(--black-color );
    
    outline: none;
    
}

.home .blog > .content > .articles > article > .description,

.swiper-blog-posts article > .description {
    
    line-height: 2;
    
    font-size: 0.9em;
    
}

.home .search .vehicle-type {

    display: inline-flex;

    width: 100%;

    flex-direction: row;

    flex-wrap: wrap;

    align-items: center;

    justify-content: center;

}

.home .search .vehicle-type > div {

    text-align: center;

}

.home .search .vehicle-type > div:not( :last-child ) {

    margin-right: 1rem;

}

.home .search .vehicle-type > div > label {

    position: relative;

    text-transform: uppercase;

}

.home .search .vehicle-type > div > label:hover {

    cursor: pointer;

    color: var( --accent-focus-color );

}

.home .search .vehicle-type > div > input[type="radio"] {

    display: none;

}

.home .search .vehicle-type > div > input[type="radio"]:checked + label::after {

    content: "";

    position: absolute;

    display: block;

    bottom: -4px;

    left: 0;

    width: 100%;

    height: 2px;

    background-color: var( --white-color );

}

.home .search .swiper-vehicle-types input[type="radio"]:checked + label {

    color: var( --accent-focus-color );

}

.home .search .search-heading {

    text-align: center;

}

.home .search .search-heading > h1 {

    font-weight: 400;

    text-transform: uppercase;

    letter-spacing: 0.1rem;

}

.home .banner a > img {

    width: 100%;

}

.swiper-vehicle-types input {

    display: none;

}

.swiper-vehicle-types .type-option {

    text-align: center;

}

.swiper-vehicle-types .swiper-button-prev, .swiper-vehicle-types .swiper-rtl .swiper-button-next {

    left: 0;

}

.swiper-vehicle-types .swiper-button-next, .swiper-vehicle-types .swiper-rtl .swiper-button-prev {

    right: 0;

}

.swiper-vehicle-types .swiper-button-next, .swiper-vehicle-types .swiper-button-prev {

    color: white;

}

.swiper-vehicle-types {

    display: none;

}

.home .benefits {

    display: none;

}

.home .benefits .list {

    display: none;

    display: grid;

    grid-gap: 1rem;

    grid-template-columns: repeat( 3, 1fr );

    text-align: center;

}

.home .benefits .list > .section > .icon {

    line-height: 0;

    font-size: 3rem;

}

.home .benefits .list > .section > .title {

    color: var( --accent-color );

    font-weight: 500;

}

.home .benefits .list > .section > *:not( :last-child ) {

    margin-bottom: 0.3rem;

}

.home .categories > div > h2 {

    text-align: center;

    text-transform: uppercase;

    margin-bottom: 2rem;

}

.home .categories > div > section {

    display: grid;

    grid-template-columns: repeat( 3, 1fr );

    grid-gap: 1rem;

}

.home .categories > div > section > .category {

    position: relative;

    width: 100%;

    background-color: var( --details-color );

    border-radius: 0.3rem;

    overflow: hidden;

}

.home .categories > div > section > .category > img {

    position: absolute;

    top: 0;

    left: 0;

    width: 100%;

    height: 100%;

    object-fit: cover;

}

.home .categories > div > section > .category::after {

    content: "";

    display: block;

    padding-bottom: 50%;

}

.home .categories > div > section > .category > .overlay {

    position: absolute;

    width: 100%;

    height: 100%;

    display: block;

    background-color: rgba( 0, 0, 0, 0.3 );

}

.home .categories > div > section > .category > .title {

    position: absolute;

    bottom: 1rem;

    left: 0;

    color: white;

    display: inline-flex;

    font-weight: 600;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

    font-size: 1.2rem;

}

.home .categories > div > section > .category > .title > span {

    background-color: var( --accent-color );

    margin-right: 0.5rem;

    width: 5px;

    height: 2rem;

}