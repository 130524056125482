.blog > .layout {

    display: flex;

    flex-direction: row;

    flex-wrap: nowrap;

}

.blog > .title > h2 {

    text-transform: uppercase;

}

.blog > .layout > aside {

    margin-right: 3em;

    width: 100%;

    max-width: 270px;

    min-width: 250px;

}

.blog > .layout > aside > div:not( :last-child ) {

    margin-bottom: 2em;

}

.blog > .layout > aside .navigation {

    border-radius: 0.3em;

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

    padding: 2em;

}

.blog > .layout > section {

    flex: 1;

}

.blog > .layout > section > div:not( :last-child ),

.blog > .layout > section .collection > div:not( :last-child ) {

    margin-bottom: 2em;

}

.blog > .layout > section .collection > .articles {

    display: grid;

    grid-template-columns: repeat( 2, 1fr );

    grid-gap: 2em;

}

.blog > .layout > section .collection > .articles > article {

    background-color: var( --color-white );

    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);

    border-radius: 0.3em;

    overflow: hidden;

}

.blog > .layout > section .collection > .articles > article > .thumbnail > a {

    position: relative;

    width: 100%;

    background-color: var( --details-color );

    display: block;

}

.blog > .layout > section .collection > .articles > article > .thumbnail > a > img {

    position: absolute;

    width: 100%;

    height: 100%;

    top: 50%;

    left: 50%;

    transform: translate( -50%, -50% );

    object-fit: cover;
        
}

.blog > .layout > section .collection > .articles > article > .thumbnail > a::after {

    content: "";

    display: block;

    padding-bottom: 50%;

}

.blog > .layout > section .collection > .articles > article > div {

    margin-bottom: 1em;

}

.blog > .layout > section .collection > .articles > article > div:not( :first-child ) {

    margin-right: 1em;

    margin-left: 1em;

}

.blog > .layout > section .collection > .articles > article > .title,

.blog > .layout > section .collection > .articles > article > .description {

    text-align: center;

}

.blog > .layout > section .collection > .articles > article > .title > a {

    text-decoration: none;

    font-weight: 500;

    color: var( --black-color );

    outline: none;

}

.blog > .layout > section .collection > .articles > article > .title > a:hover {

    color: var( --accent-color );

}

.blog > .layout > section .collection > .articles > article > .description {

    line-height: 2;

    font-size: 0.9em;

}

.blog > .layout > section > .post-content > p {

    line-height: 2;

}

.blog > .layout > section > .post-content > p:not( :last-child ) {

    margin-bottom: 1em;

}

.blog > .layout > section > .post-content > p iframe {

    max-width: 100%;

}

.blog .pagination > button > svg{

    pointer-events: none;

}

.blog .pagination > button {

    line-height: 0;

    font-size: 20px;

    background: none;

    border: 2px solid var( --secondary-text-color );

    color: var( --secondary-text-color );

    border-radius: 50%;

    text-align: center;

    padding: 5px;

    outline: none;

}

.blog .pagination > button:hover {

    border-color: var( --accent-color );

    background-color: var( --accent-color );

    color: var( --white-color );

    cursor: pointer;

}

.blog .pagination > button:disabled {

    opacity: 0.3;

}

.blog .pagination > button:hover:disabled {

    color: var( --secondary-text-color );

    border-color: var( --secondary-text-color );

    background-color: transparent;

    cursor: default;

}

.blog .pagination > button:not( :last-child ) {

    margin-right: 1em;

}