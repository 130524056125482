@font-face {

    font-family: 'Poppins';

    font-style: normal;

    font-weight: 900;

    src:    url( '../Fonts/Poppins-Black.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-Black.ttf' ) 
            
            format( 'truetype' );

}

@font-face {

    font-family: 'Poppins';

    font-style: italic;

    font-weight: 900;

    src:    url( '../Fonts/Poppins-BlackItalic.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-BlackItalic.ttf' ) 
            
            format( 'truetype' );

}

@font-face {

    font-family: 'Poppins';

    font-style: normal;

    font-weight: 800;

    src:    url( '../Fonts/Poppins-ExtraBold.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-ExtraBold.ttf' ) 
            
            format( 'truetype' );

}

@font-face {

    font-family: 'Poppins';

    font-style: italic;

    font-weight: 800;

    src:    url( '../Fonts/Poppins-ExtraBoldItalic.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-ExtraBoldItalic.ttf' ) 
            
            format( 'truetype' );

}

@font-face {

    font-family: 'Poppins';

    font-style: normal;

    font-weight: 700;

    src:    url( '../Fonts/Poppins-Bold.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-Bold.ttf' ) 
            
            format( 'truetype' );

}

@font-face {

    font-family: 'Poppins';

    font-style: italic;

    font-weight: 700;

    src:    url( '../Fonts/Poppins-BoldItalic.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-BoldItalic.ttf' ) 
            
            format( 'truetype' );

}

@font-face {

    font-family: 'Poppins';

    font-style: normal;

    font-weight: 600;

    src:    url( '../Fonts/Poppins-SemiBold.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-SemiBold.ttf' ) 
            
            format( 'truetype' );

}

@font-face {

    font-family: 'Poppins';

    font-style: italic;

    font-weight: 600;

    src:    url( '../Fonts/Poppins-SemiBoldItalic.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-SemiBoldItalic.ttf' ) 
            
            format( 'truetype' );

}

@font-face {

    font-family: 'Poppins';

    font-style: normal;

    font-weight: 500;

    src:    url( '../Fonts/Poppins-Medium.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-Medium.ttf' ) 
            
            format( 'truetype' );

}

@font-face {

    font-family: 'Poppins';

    font-style: italic;

    font-weight: 500;

    src:    url( '../Fonts/Poppins-MediumItalic.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-MediumItalic.ttf' ) 
            
            format( 'truetype' );

}

@font-face {

    font-family: 'Poppins';

    font-style: normal;

    font-weight: 400;

    src:    url( '../Fonts/Poppins-Regular.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-Regular.ttf' ) 
            
            format( 'truetype' );

}

@font-face {

    font-family: 'Poppins';

    font-style: normal;

    font-weight: 300;

    src:    url( '../Fonts/Poppins-Light.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-Light.ttf' ) 
            
            format( 'truetype' );

}

@font-face {

    font-family: 'Poppins';

    font-style: italic;

    font-weight: 300;

    src:    url( '../Fonts/Poppins-LightItalic.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-LightItalic.ttf' ) 
            
            format( 'truetype' );

}

@font-face {

    font-family: 'Poppins';

    font-style: normal;

    font-weight: 200;

    src:    url( '../Fonts/Poppins-Light.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-Light.ttf' ) 
            
            format( 'truetype' );

}

@font-face {

    font-family: 'Poppins';

    font-style: italic;

    font-weight: 200;

    src:    url( '../Fonts/Poppins-LightItalic.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-LightItalic.ttf' ) 
            
            format( 'truetype' );

}

@font-face {

    font-family: 'Poppins';

    font-style: normal;

    font-weight: 100;

    src:    url( '../Fonts/Poppins-ExtraLight.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-ExtraLight.ttf' ) 
            
            format( 'truetype' );

}

@font-face {

    font-family: 'Poppins';

    font-style: italic;

    font-weight: 100;

    src:    url( '../Fonts/Poppins-ExtraLightItalic.ttf' );

    src:    local( 'Poppins' ), local( 'Poppins' ),

            url( '../Fonts/Poppins-ExtraLightItalic.ttf' ) format( 'truetype' );

}

body {

    font-family: 

        'Poppins',

        sans-serif;

    -webkit-font-smoothing: antialiased;

    -moz-osx-font-smoothing: grayscale;

        overflow-x: hidden;

        margin: auto;

        padding: 0;

        width: 100%;

}

/* 
*
* Root Variables
*
* Theme Color Scheme
*
*/

:root {

    --Content-Width: 1280px;

    --accent-color: #c92323;

    --accent-focus-color: #ffcc00;

    --white-color: white;

    --black-color: black;

    --details-color: #d2d2d2;

    --details-hover-color: #bbb;

    --footer-color: #0d0f14;

    --secondary-text-color: #8e8e8e;

    /* Indexed colorful label colors */

    --indexed-label-col-a: #259547;

    --indexed-label-col-b: #74ae3f;

    --indexed-label-col-c: #cad328;

    --indexed-label-col-d: #feed00;

    --indexed-label-col-e: #eab816;

    --indexed-label-col-f: #cf651a;

    --indexed-label-col-g: #c00d18;

    --indexed-label-col-p: #d2d2d2;

    --indexed-label-col-x: #d2d2d2;

    --indexed-label-col-o: #d2d2d2;

}

* {

    margin: 0;

    padding: 0;

    box-sizing: border-box;

    list-style-type: none;

}

.max-content-width {

    margin: auto;

    width: 100%;

    max-width: var( --Content-Width );

    padding-right: 3em;

    padding-left: 3em;

}

.application {

    position: relative;

    display: flex;

    flex-direction: column;

    flex-wrap: nowrap;

    min-height: 100vh;

}

.layout { flex: 1; }

.layout > .container > div:not( :last-child ),

.layout > .container > .content > div:not( :last-child ) {

    margin-bottom: 2em;

}

.component-loader {

    display: flex;

    align-items: center;

    flex-wrap: nowrap;

    flex-direction: row;

    justify-content: center;

}

.component-loader > span {

    margin-left: 16px;

    font-size: 12px;

    font-weight: 500;

    text-transform: uppercase;

    letter-spacing: 1px;

}

.component-loader-container {

    width: 32px;

    height: 32px;

}

.component-loader-wave {

    border-radius: 50%;

    position: relative;

    opacity: 1;

}

.component-loader-wave:before, .component-loader-wave:after {

    content:'';

    border: 1px #000 solid;

    border-radius: 50%;

    width: 100%;

    height: 100%;

    position: absolute;

    left:0px;

}

.component-loader-wave:before {

    transform: scale( 1, 1 );

    opacity: 1;

    -webkit-animation: componentWaveBefore 0.6s infinite linear;

    animation: componentWaveBefore 0.6s infinite linear;

}

.component-loader-wave:after {

    transform: scale( 0, 0 );

    opacity: 0;

    -webkit-animation: componentWaveAfter 0.6s infinite linear;

    animation: componentWaveAfter 0.6s infinite linear;

}

@-webkit-keyframes componentWaveAfter {

    from { -webkit-transform: scale( 0.5, 0.5 ); opacity: 0; }

    to { -webkit-transform: scale( 1, 1 ); opacity: 1; }

}

@keyframes componentWaveAfter {

    from { transform: scale( 0.5, 0.5 ); opacity: 0; }

    to { transform: scale( 1, 1 ); opacity: 1; }

}

@-webkit-keyframes componentWaveBefore {

    from { -webkit-transform: scale( 1, 1 ); opacity: 1; }

    to { -webkit-transform: scale( 1.5, 1.5 ); opacity: 0; }

}

@keyframes componentWaveBefore {

    from { -webkit-transform: scale( 1, 1 ); opacity: 1; }

    to { -webkit-transform: scale( 1.5, 1.5 ); opacity: 0; }

}

.temp-notice {

    border: 1px solid;

    padding: 1em;

    font-size: 0.8em;

    border-radius: 0.3em;

    display: flex;

    align-items: center;

    flex-direction: row;

    flex-wrap: nowrap;

}

.temp-mini-notice {

    border: 1px solid;

    padding: 1em;

    font-size: 0.8em;

    border-radius: 0.3em;

    display: flex;

    align-items: center;

    flex-direction: column;

    flex-wrap: nowrap;

    text-align: center;

}

.temp-notice > div:not( :last-child ) {

    margin-right: 1em;

}

.temp-mini-notice > div:not( :last-child ) {

    margin-bottom: 1em;

}

.temp-notice > .message,

.temp-mini-notice > .message {

    flex: 1;

}

.temp-notice > .icon,

.temp-mini-notice > .icon {

    font-size: 20px;

    line-height: 0;

    opacity: 0.5;

}

.temp-notice > .tag,

.temp-mini-notice > .tag {

    border: 1px solid;

    padding: 5px 10px;

    text-transform: uppercase;

    letter-spacing: 1px;

    font-size: 0.8em;

    font-weight: 600;

    border-radius: 0.3em;

}

.temp-notice-missing {

    border-color: #b3e5fc;

    background-color: #e1f5fe;

    color: #01579b;

}

.temp-notice-incomplete {

    border-color: #ffe0b2;

    background-color: #fff3e0;

    color: #e65100;

}

.temp-notice-undefined {

    border-color: #ffcdd2;

    background-color: #ffebee;

    color: #b71c1c;

}

label.select {

    position: relative;

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    align-items: center;

    border-radius: 0.3em;

}

label.select > select {

    background: none;

    outline: none;

    padding-top: 10px;

    padding-bottom: 10px;

    padding-left: 10px;

    padding-right: 35px;

}

label.select > span {

    position: absolute;

    line-height: 0;

    right: 10px;

    pointer-events: none;

}

label.shadow-select {

    box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);

}

label.shadow-select > select {

    border: none;

    appearance: none;

    font-size: 16px;

}

label.default-select {

    border: 2px solid var( --details-color );

}

label.default-select > select {

    border: none;

    appearance: none;

    font-size: 16px;

}

label.checkbox {

    display: inline-flex;

    width: 100%;

    /* display: flex;
  
    align-items: center;
  
    flex-direction: row;
    
    flex-wrap: nowrap; */

}

label.checkbox:hover { cursor: pointer; }

label.checkbox > input[ type="checkbox" ] { display: none; }

label.checkbox > input[type="checkbox"]:checked ~ span.check { border-color: var( --black-color ); }

label.checkbox > span.check > span.checked {

    visibility: hidden;

    line-height: 0;

    font-size: 12px;

}

label.checkbox > input[type="checkbox"]:checked ~ span.check > span.checked { visibility: visible; }

label.checkbox > span.check {

    position: relative;

    display: flex;

    justify-content: center;

    align-items: center;

    width: 20px;
  
    height: 20px;
  
    border-radius: 0.3em;
  
    border: 2px solid var( --details-color );

}

label.checkbox > span.check.error {

    border-color: var( --accent-color );

}

label.checkbox > span.name {

    font-size: 0.9em;

    margin-left: 10px;

    flex: 1;

}

.site-policy {

    position: fixed;

    background-color: white;

    bottom: 0;

    width: 100%;

    box-shadow: 0 -5px 10px 0 rgb(0 0 0 / 10%);

    z-index: 991;

}

.site-policy .policy {

    display: inline-flex;

    flex-direction: row;

    flex-wrap: nowrap;

    justify-content: space-between;

    align-items: center;

    width: inherit;

    padding-top: 2rem;

    padding-bottom: 2rem;

}

.site-policy .policy > *:not( :last-child ) {

    margin-right: 2rem;

}

.site-policy .policy > .announcement {

    flex: 1;

}

.site-policy .policy > .announcement > div:not( :last-child ) {

    margin-bottom: 0.5rem;

}

.site-policy .policy > .announcement > .title {

    font-weight: 500;

}

.site-policy .policy > .announcement > .text {

    font-size: 0.9rem;

    line-height: 2;

}

.site-policy .policy > .announcement > .text > a {

    color: var( --accent-color );

}

.site-policy .policy > .confirm-policy {

    background-color: #0090ff;

    color: var(--white-color );

    font-weight: 600;

    border: none;

    padding: 1rem;

    border-radius: 0.3rem;

    outline: none;

}

.site-policy .policy > .confirm-policy:hover, 

.site-policy .policy > .confirm-policy:focus {

    cursor: pointer;

    background-color: #000;

}

.site-policy .policy > img {

    max-width: 128px;

}

.show-768 {

    display: none;

}